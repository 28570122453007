import { Sheet } from '@/entities/Sheet'
import { useCreateRowsMutation } from '@/features/table/createRows'
import React, { FC } from 'react'
import { Button, FormInstance } from 'antd'
import { CloseOutlined, PlusOutlined, UploadOutlined } from '@ant-design/icons'
import ButtonGroup from 'antd/es/button/button-group'

import { useGetNewRowsMetadataMutation } from '@/features/table/createRows/api/useGetNewRowsMetadataMutation'
import { useBooleanState } from '@/shared/lib/hooks/useBooleanState'

type Props = {
  className?: string
  sheet: Sheet
  form: FormInstance
  metadataMutationBag: ReturnType<typeof useGetNewRowsMetadataMutation>
  creationMutationBag: ReturnType<typeof useCreateRowsMutation>
  toggleState: ReturnType<typeof useBooleanState>
}

export const RowCreationControls: FC<Props> = ({
  sheet,
  toggleState,
  form,
  metadataMutationBag,
  creationMutationBag
}) => {
  const [isCreating, toggleCreation] = toggleState

  return isCreating ? (
    <ButtonGroup size='large'>
      <Button
        type='primary'
        loading={creationMutationBag.isLoading}
        icon={<UploadOutlined />}
        onClick={form.submit}
      >
        Submit
      </Button>
      <Button onClick={toggleCreation} type='dashed' icon={<CloseOutlined />}>
        Discard
      </Button>
    </ButtonGroup>
  ) : (
    <Button
      onClick={() => {
        metadataMutationBag.mutate(
          {
            sheet,
            rows: [
              {
                column_names: [],
                row_data: {}
              }
            ]
          },
          {
            onSuccess: toggleCreation
          }
        )
      }}
      size='large'
      loading={metadataMutationBag.isLoading}
      icon={<PlusOutlined />}
    >
      Create row
    </Button>
  )
}
