import React, { FC } from 'react'
import { UseMutationResult } from 'react-query'

import { ConfigSheetDrawer, useCreateSheetsMutation } from '@/entities/Sheet'
import { Spreadsheet } from '@/entities/Spreadsheet'

import { createNewSheetConfig } from '../model'
import { useConfigVerifyMutation } from '../api'

type Props = {
  spreadsheet: Spreadsheet
}

export const CreateBlankSheetDrawer: FC<Props> = ({
  children,
  spreadsheet
}) => {
  const sheetConfig = createNewSheetConfig(spreadsheet)

  const verifyMutationBag = useConfigVerifyMutation() as UseMutationResult
  const performMutationBag = useCreateSheetsMutation() as UseMutationResult

  return (
    <ConfigSheetDrawer
      sheet={sheetConfig}
      verifyMutationBag={verifyMutationBag}
      performMutationBag={performMutationBag}
    >
      {children}
    </ConfigSheetDrawer>
  )
}
