import React, { FC, useEffect } from 'react'
import { Skeleton, Spin } from 'antd'

import { GoogleAuthButton } from '@/features/auth/login/viaGoogle/ui/GoogleAuthButton'
import { useBooleanState } from '@/shared/lib/hooks/useBooleanState'

const scriptClass = 'googleAuthScript'

export const AuthWithGoogle: FC = () => {
  const [isScriptReady, , setScriptReady] = useBooleanState()
  const [isAuthPending, toggleAuthPending] = useBooleanState()

  useEffect(() => {
    if (document.querySelector(`.${scriptClass}`)) {
      !isScriptReady && setScriptReady()
      return
    }

    const script = document.createElement('script')
    script.className = scriptClass
    script.src = 'https://accounts.google.com/gsi/client'
    script.onload = setScriptReady
    document.getElementById('root')!.appendChild(script)
  }, [isScriptReady, setScriptReady])

  if (!isScriptReady)
    return <Skeleton.Button active style={{ width: 200, height: '3em' }} />

  return (
    <Spin spinning={isAuthPending}>
      <GoogleAuthButton toggleAuthPending={toggleAuthPending} />
    </Spin>
  )
}
