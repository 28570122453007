import React, { FC } from 'react'
import { useQueryClient } from 'react-query'
import { ViewerAccountInfo, viewerKeys } from '@/entities/Viewer'

import sx from './styles.less'

export const ViewerAvatar: FC = () => {
  const qClient = useQueryClient()
  const userData = qClient.getQueryData<ViewerAccountInfo>(viewerKeys.account)

  return (
    <span className={sx.avatar}>
      {userData?.auth_user.provider_user.first_name[0].toUpperCase()}
    </span>
  )
}
