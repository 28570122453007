import { useQuery } from 'react-query'

import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys, SpreadsheetPreview } from '@/entities/Spreadsheet'

export interface SpreadsheetMetadata extends SpreadsheetPreview {
  owner_first_name: string
  owner_last_name: string
}

type GetSpreadsheetsResponse = { spreadsheets: SpreadsheetMetadata[] }

export const useGetSpreadsheetsQuery = () =>
  useQuery(spreadsheetKeys.list, async () => {
    const response = await fetcher<GetSpreadsheetsResponse>({
      description: 'Get all spreadsheets',
      url: '/spreadsheets'
    })

    return response.data.spreadsheets
  })
