import React, { FC, useMemo } from 'react'
import { Spin, Typography } from 'antd'

import { diffToCodeBlock, isSingleDiff } from './lib'
import { ChangeRecord, ChangeRecordList } from '@/shared/lib/types'

import sx from './styles.less'

type Props = {
  actionButton?: JSX.Element
  changes: ChangeRecordList | ChangeRecord
}

export const ChangeList: FC<Props> = ({ actionButton, changes }) => {
  const changesContents = useMemo(() => {
    if (!changes) return null

    if (isSingleDiff(changes)) {
      const change = diffToCodeBlock(changes)
      return change.some((block) => block) ? (
        change
      ) : (
        <h3 className={sx.noChanges}>No changes</h3>
      )
    } else {
      return changes.sheets.map(diffToCodeBlock)
    }
  }, [changes])

  return (
    <div>
      <div className={sx.heading}>
        <Typography.Title level={4}>Change(s): </Typography.Title>
        {actionButton}
      </div>
      <Spin spinning={!changes}>
        <div className={sx.diffContainer}>{changesContents}</div>
      </Spin>
    </div>
  )
}
