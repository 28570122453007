import { SheetConfig } from '@/entities/Sheet'
import { Spreadsheet } from '@/entities/Spreadsheet'
import { ColumnValueType } from '@/entities/Column/model'

export const createNewSheetConfig = (
  spreadsheet: Spreadsheet
): SheetConfig => ({
  spreadsheet_id: spreadsheet.id,
  name: '',
  columns: [
    {
      id: 'id',
      name: 'id',
      column_type: ColumnValueType.AutoIncrement,
      required: true,
      is_primary: true,
      length: 128,
      validation_config: {
        validation_type: null
      }
    }
  ]
})
