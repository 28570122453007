import { useQuery } from 'react-query'

import { sheetKeys } from '@/entities/Sheet/model/keyFactory'
import { fetcher } from '@/shared/lib/fetcher/config'

type SheetData<T = any> = {
  name: string
  data: Array<T>
}

export const useGetSheetDataQuery = <D = any>(ss_id: string, name: string) =>
  useQuery(sheetKeys.dataOf(ss_id, name), async ({ queryKey }) => {
    const [ss_id, sheet_id] = queryKey.slice(1)
    const response = await fetcher<SheetData<D>>({
      description: `Get data of sheet "${sheet_id}"`,
      url: `spreadsheets/${ss_id}/sheets/${sheet_id}/data`
    })

    return response.data.data
  })
