import React, { FC, useCallback } from 'react'
import { Form, Input } from 'antd'
import { useSheetConfigContext } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/model'
import { inputRules } from './model'

import sx from './styles.less'

export const SheetNameInput: FC = () => {
  const [state, setPart] = useSheetConfigContext()

  const [form] = Form.useForm<{ name?: string }>()
  const updateName = useCallback(() => {
    const { name } = form.getFieldsValue()
    setPart('name', name)
  }, [form, setPart])

  return (
    <Form
      form={form}
      onValuesChange={updateName}
      initialValues={{ name: state.name || '' }}
      className={sx.nameForm}
    >
      <Form.Item
        name='name'
        label='Sheet name'
        rules={inputRules}
        className={sx.name}
      >
        <Input size='large' />
      </Form.Item>
    </Form>
  )
}
