import React, { FC } from 'react'
import { useStepState } from '@/shared/lib/hooks/useStepState'
import { useComplexState } from '@/shared/lib/hooks/useComplexState'
import { SheetConfig } from '@/entities/Sheet'
import { StepContext, SheetConfigContext, useStepContext } from './model'
import { SheetConfigMenu } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/ui/SheetConfigMenu'
import { ChangesComparison } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/ui/ChangesComparison'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { Steps } from 'antd'

import sx from './styles.less'

const { Step } = Steps

const StepContent: FC = () => {
  const [currentStep] = useStepContext()

  switch (currentStep) {
    case 0: {
      return <SheetConfigMenu />
    }
    case 1: {
      return <ChangesComparison />
    }
    default:
      return <div />
  }
}

type Props = {
  sheet: SheetConfig
}

export const ConfigSheetSteps: FC<Props> = ({ sheet }) => {
  const sheetConfigState = useComplexState<SheetConfig>(sheet)
  const stepState = useStepState()
  const [currentStep] = stepState

  return (
    <StepContext.Provider value={stepState}>
      <SheetConfigContext.Provider value={sheetConfigState}>
        <Steps current={currentStep} className={sx.steps}>
          <Step icon={<EditOutlined />} title='Configure sheet' />
          <Step icon={<CheckOutlined />} title='Approve changes' />
        </Steps>
        <StepContent />
      </SheetConfigContext.Provider>
    </StepContext.Provider>
  )
}
