import React, { FC } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { AppLayout } from '@/widgets/universal/AppLayout'
import { SignInPage, SpreadsheetsListPage, SpreadsheetPage } from '@/pages'
import { RedirectToActiveSheet } from '@/pages/Spreadsheet/RedirectToActiveSheet'
import { InfoText } from '@/shared/ui/InfoText'

export const AppRoutes: FC = () => (
  <Routes>
    <Route path='/sign_in' element={<SignInPage />} />
    <Route path='/' element={<AppLayout />}>
      <Route
        path='*'
        element={
          <main style={{ padding: '1rem' }}>
            <InfoText>There's nothing here!</InfoText>
          </main>
        }
      />
      <Route index element={<Navigate to='spreadsheets' />} />
      <Route path='spreadsheets'>
        <Route index element={<SpreadsheetsListPage />} />
        <Route path=':ss_id' element={<Outlet />}>
          <Route index element={<RedirectToActiveSheet />} />
          <Route path=':sheet_name' element={<SpreadsheetPage />} />
        </Route>
      </Route>
      <Route
        path='other'
        element={<InfoText>Expect more in future releases</InfoText>}
      />
    </Route>
  </Routes>
)
