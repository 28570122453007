import { Sheet } from '@/entities/Sheet'
import { normalizeRowValues } from '@/features/table/createRows/lib'
import { FormInstance } from 'antd'
import { useCallback } from 'react'

import { useGetNewRowsMetadataMutation } from '@/features/table/createRows/api/useGetNewRowsMetadataMutation'

import { CreateRowFormValues } from '../types'

type Mutate = ReturnType<typeof useGetNewRowsMetadataMutation>['mutate']

export const useRequestMetadataCallBack = (
  form: FormInstance,
  mutate: Mutate,
  sheet: Sheet
) =>
  useCallback(
    (_, values: CreateRowFormValues) => {
      const { rows: rowsFilteredByTouched }: CreateRowFormValues =
        form.getFieldsValue(true, ({ touched }) => touched)

      mutate({
        sheet,
        rows: values.rows.map((row, rowIdx) => {
          const rowFieldsTouchedState = rowsFilteredByTouched[rowIdx]
          const column_names = Object.keys(rowFieldsTouchedState)
          return {
            column_names,
            row_data: normalizeRowValues(
              sheet.columns,
              row,
              rowFieldsTouchedState
            )
          }
        })
      })
    },
    [form, mutate, sheet]
  )
