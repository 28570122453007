import { getCellMetadata } from '@/entities/Sheet/lib'
import { ICellRendererParams } from 'ag-grid-community'
import React, { memo } from 'react'

import { MetaIndicators } from './ui'
import { getValueToDisplay } from './model'

type Props = ICellRendererParams

export const CellRenderer = memo<Props>(({ data, value, colDef }) => {
  const isDropdown = !!getCellMetadata({ data, colDef })?.options?.length
  return (
    <MetaIndicators meta={data.__bg_meta} colName={colDef?.field}>
      {getValueToDisplay({
        value,
        colDef,
        isDropdown
      })}
    </MetaIndicators>
  )
})
