import React, { FC } from 'react'
import { SettingOutlined } from '@ant-design/icons'

import { Sheet } from '@/entities/Sheet'
import { ConfigureSheet } from '@/features/sheet/configure'

import { useSpreadsheetParams } from '@/shared/lib/hooks'
import { cx } from '@/shared/lib/classnames'
import sx from './styles.less'

type Props = {
  sheet: Sheet
}

export const SheetTab: FC<Props> = ({ sheet }) => {
  const { sheet_name } = useSpreadsheetParams()

  const isTabActive = sheet_name === sheet.name

  return (
    <div className={cx(sx.tab, isTabActive && sx.active)}>
      {sheet.name}
      <ConfigureSheet sheet={sheet}>
        <SettingOutlined className={sx.icon} />
      </ConfigureSheet>
    </div>
  )
}
