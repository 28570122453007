import React, { FC } from 'react'
import { Typography } from 'antd'
import { TitleProps } from 'antd/es/typography/Title'

import sx from './styles.less'
import { cx } from '@/shared/lib/classnames'

export const InfoText: FC<TitleProps> = ({ className, level, ...props }) => (
  <Typography.Title
    level={level || 2}
    className={cx(sx.title, className)}
    {...props}
  />
)
