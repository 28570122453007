import { Sheet } from '@/entities/Sheet'
import { Row, RowMetadata, sheetKeys } from '@/entities/Sheet/model'

import { fetcher } from '@/shared/lib/fetcher/config'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { CreationError } from './types'
import { useMutation, useQueryClient } from 'react-query'

type Base = {
  rows: Array<{
    column_names: string[]
    row_data: object
  }>
}

type GetRowsMetadataParams = Base & {
  sheet: Sheet
}

type GetRowsMetadataPayload = Base & {
  update_type: 'CREATING_DRY_RUN'
}

type GetRowsMetadataResponse = {
  rows: Row[]
}

export const useGetNewRowsMetadataMutation = () => {
  const qClient = useQueryClient()

  return useMutation<
    GetRowsMetadataResponse,
    FetcherError<CreationError>,
    GetRowsMetadataParams
  >(
    async ({ sheet, rows }) => {
      const response = await fetcher<
        GetRowsMetadataPayload,
        GetRowsMetadataResponse
      >({
        description: 'Validate rows config',
        method: 'put',
        params: {
          on_validation_err: 'RAISE_ALL_ERRORS'
        },
        url: `/spreadsheets/${sheet.spreadsheet_id}/sheets/${sheet.id}/data`,
        data: {
          rows: rows.map((row) => ({ ...row, curr_row_data: {} })),
          update_type: 'CREATING_DRY_RUN'
        }
      })

      return response.data
    },
    {
      onSuccess({ rows }) {
        qClient.setQueryData<RowMetadata[]>(
          sheetKeys.rowsCreationMetadata(),
          (prevMeta) => {
            const newMeta = prevMeta || []

            rows.forEach((row, row_idx) => {
              newMeta[row_idx] = row.__bg_meta.columns
            })

            return newMeta
          }
        )
      },
      onError({ payload }) {
        if (payload) {
          qClient.setQueryData<RowMetadata[]>(
            sheetKeys.rowsCreationMetadata(),
            (prevMeta) => {
              const newMeta = prevMeta || []

              payload.column_errors.forEach((record) => {
                newMeta[record.row_idx][record.column_name].err_msg =
                  record.err_msg
              })

              return newMeta
            }
          )
        }
      }
    }
  )
}
