import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, message } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { useConfigUploadMutation } from '@/features/sheet/create/createFromFile/api/useConfigUploadMutation'
import { makeMessageClosable } from '@/shared/lib/message'
import { useStepContext } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm/model'
import { ChangeList } from '@/shared/ui/ChangeDashboard'
import { ChangeRecordList } from '@/shared/lib/types'

import sx from './styles.less'

export const DiffComparison: FC = () => {
  const form = useFormInstance()
  const values = form.getFieldsValue(true)

  const navigate = useNavigate()

  const [, goPrev] = useStepContext()

  const diffs: ChangeRecordList = values.schemaDescription

  const { mutate, isLoading } = useConfigUploadMutation<'submit'>()

  const handleCreate = useCallback(() => {
    const { config, file, db_id } = values

    const formData = new FormData()
    formData.append('file', file.file.originFileObj)

    const json = JSON.stringify(config)
    const blob = new Blob([json], {
      type: 'application/json'
    })
    formData.append('json_data', blob)

    mutate(
      { db_id, formData, action: 'submit' },
      {
        onSuccess(data) {
          message.success({
            content: `Populated spreadsheet accordingly to config`,
            duration: 5,
            ...makeMessageClosable()
          })
          navigate(db_id + '/' + data.sheets[0].name)
        }
      }
    )
  }, [mutate, navigate, values])

  return (
    <div>
      <ChangeList
        changes={diffs}
        actionButton={
          <Button
            type='primary'
            size='large'
            loading={isLoading}
            onClick={handleCreate}
          >
            Create
          </Button>
        }
      />
      <Button
        size='large'
        type='link'
        onClick={goPrev}
        className={sx.btn}
        icon={<LeftOutlined />}
      >
        To config edit
      </Button>
    </div>
  )
}
