import { useGetNewRowsMetadataMutation } from '@/features/table/createRows/api/useGetNewRowsMetadataMutation'
import React, { FC } from 'react'
import { Collapse } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { useBooleanState } from '@/shared/lib/hooks'
import {
  RowCreationControls,
  RowCreationForm,
  useCreateRowsMutation
} from '@/features/table/createRows'
import { Sheet } from '@/entities/Sheet'

import { PropagationBlocker } from './lib'
import { TableHeadContent } from './ui/TableHeadContent'

import sx from './styles.less'
const { Panel } = Collapse

type Props = {
  spreadsheetName: string
  sheet: Sheet
}

export const SheetTableHeader: FC<Props> = ({ spreadsheetName, sheet }) => {
  const panelStateBag = useBooleanState()
  const [isPanelOpen] = panelStateBag
  const creationMutationBag = useCreateRowsMutation()
  const metadataMutationBag = useGetNewRowsMetadataMutation()
  const [form] = useForm()

  return (
    <Collapse
      className={sx.root}
      collapsible='header'
      expandIconPosition='right'
      destroyInactivePanel
      // open (true) -> 1, closed (false) -> 0
      activeKey={Number(isPanelOpen)}
    >
      <Panel
        showArrow={false}
        extra={
          <PropagationBlocker className={sx.extra}>
            <TableHeadContent spreadsheetName={spreadsheetName} />
          </PropagationBlocker>
        }
        header={
          <PropagationBlocker>
            <RowCreationControls
              sheet={sheet}
              form={form}
              toggleState={panelStateBag}
              creationMutationBag={creationMutationBag}
              metadataMutationBag={metadataMutationBag}
            />
          </PropagationBlocker>
        }
        key={1}
      >
        <RowCreationForm
          creationMutationBag={creationMutationBag}
          metadataMutationBag={metadataMutationBag}
          form={form}
          sheet={sheet}
        />
      </Panel>
    </Collapse>
  )
}
