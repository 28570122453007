import React, { FC } from 'react'
import { Layout, Menu, MenuItemParams } from 'antd'
import { Link } from 'react-router-dom'
import { EllipsisOutlined, HomeFilled } from '@ant-design/icons'

import sx from './styles.less'

const { Sider } = Layout

type Props = {
  isOpen: boolean
}

const menuItems: MenuItemParams[] = [
  { label: <Link to='/'>Home</Link>, key: 'home', icon: <HomeFilled /> },
  {
    label: <Link to='/other'>Other</Link>,
    key: 'other',
    icon: <EllipsisOutlined />
  }
]

export const AppSider: FC<Props> = ({ isOpen }) => (
  <Sider
    trigger={null}
    collapsible
    collapsed={!isOpen}
    collapsedWidth={0}
    className={sx.appSider}
  >
    <Menu mode='inline' items={menuItems} />
  </Sider>
)
