import { Column } from '@/entities/Column'
import {
  Divider,
  Form,
  FormProps,
  Input,
  InputNumber,
  Select,
  Switch
} from 'antd'
import React, { FC } from 'react'

import { columnTypeOptions, nameRules, validationTypeOptions } from './model'

import sx from './styles.less'

type Props = {
  column: Column
  formProps?: Partial<FormProps>
  nameChangeDisabled?: true
}

export const ColumnEditor: FC<Props> = ({
  column,
  formProps,
  nameChangeDisabled
}) => {
  const [form] = Form.useForm<Column>()
  const isValidationTypeSet = Form.useWatch(
    ['validation_config', 'validation_type'],
    form
  )

  return (
    <div className={sx.root}>
      <div className={sx.heading}>
        <Divider className={sx.headDivider} dashed plain>
          Parameters
        </Divider>
        <Divider className={sx.headDivider} dashed plain>
          Validation
        </Divider>
      </div>
      <Form<Column>
        name={column.name}
        className={sx.form}
        size='large'
        form={form}
        initialValues={column}
        preserve={false}
        {...formProps}
      >
        <div className={sx.fieldsWrapper}>
          <Form.Item name='name' label='Name' required rules={nameRules}>
            <Input disabled={nameChangeDisabled} />
          </Form.Item>
          <Form.Item name='column_type' label='Type' valuePropName='selected'>
            <Select
              options={columnTypeOptions}
              defaultValue={column.column_type}
            />
          </Form.Item>
          <Form.Item name='length' label='Max. length'>
            <InputNumber defaultValue={column.length ?? undefined} />
          </Form.Item>
          <Form.Item
            name='is_primary'
            label='Is primary key'
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
          <Form.Item
            name='required'
            label='Is required'
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
        </div>

        <div className={sx.divider} />

        <div className={sx.fieldsWrapper}>
          <Form.Item
            name={['validation_config', 'validation_type']}
            label='Validation Type'
            valuePropName='selected'
          >
            <Select
              options={validationTypeOptions}
              defaultValue={column.validation_config.validation_type}
            />
          </Form.Item>
          <Form.Item
            name={['validation_config', 'python_code']}
            label='Code'
            dependencies={[['validation_config', 'validation_type']]}
            rules={[
              {
                validator: async (_, value) => {
                  if (
                    !value ||
                    !form.getFieldValue([
                      'validation_config',
                      'validation_type'
                    ])
                  )
                    form.setFields([
                      {
                        name: ['validation_config', 'python_code'],
                        value: null
                      }
                    ])
                }
              }
            ]}
          >
            <Input.TextArea disabled={!isValidationTypeSet} />
          </Form.Item>
        </div>
      </Form>
    </div>
  )
}
