import { FC, HTMLAttributes, MouseEvent } from 'react'

const stopPropagation = (event: MouseEvent<HTMLElement>) =>
  event.stopPropagation()

export const PropagationBlocker: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  ...props
}) => (
  <div onClick={stopPropagation} {...props}>
    {children}
  </div>
)
