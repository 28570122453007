import React, { PropsWithChildren, useEffect } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import { InfoText } from '@/shared/ui/InfoText'

import { useValidateAppKeyQuery } from '../api'
import { initAuthInterceptors } from '../model/axiosInterceptors'

import sx from './styles.less'

export const AuthWrapper = ({ children }: PropsWithChildren<any>) => {
  const { data: authData, isError, isLoading } = useValidateAppKeyQuery()
  const navigate = useNavigate()

  const isOnSignin = !!useMatch('sign_in')
  useEffect(() => {
    if (!isLoading && !isOnSignin && !authData) navigate('/sign_in')
  })

  //Here we add credentials injection logic as well as logout on JWT expiration
  useEffect(initAuthInterceptors, [])

  if (isLoading && !isError)
    return (
      <InfoText level={1} className={sx.loadText}>
        Signing in...
      </InfoText>
    )

  return children
}
