import React, { FC, useCallback, useMemo } from 'react'
import { useStepContext } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm/model/StepContext'
import { Button, Form, message, Upload, UploadProps } from 'antd'
import { useFileUploadMutation } from '@/features/sheet/create/createFromFile/api/useFileUploadMutation'
import { RightOutlined, UploadOutlined } from '@ant-design/icons'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

import { makeMessageClosable } from '@/shared/lib/message'
import { LoadSpinner } from '@/shared/ui/LoadSpinner'
import { UploadFile } from 'antd/es/upload/interface'
import { FormValues } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm'

import sx from './styles.less'

const { Dragger } = Upload

export const FileUpload: FC = () => {
  const form = useFormInstance<FormValues>()
  const { mutate, isLoading } = useFileUploadMutation()
  const [, , goNext] = useStepContext()

  // @ts-ignore
  const handleUpload = useCallback<UploadProps['customRequest']>(
    (params) => {
      const formData = new FormData()

      formData.append('file', params.file)
      const db_id = form.getFieldValue('db_id')

      mutate(
        { db_id, formData },
        {
          onSuccess(config) {
            const file: UploadFile = form.getFieldValue('file').file
            file.status = 'success'
            form.setFieldsValue({ config, file })

            goNext()
          },
          onError(error) {
            form.setFieldsValue({ file: undefined })
            message.error({
              content: 'Failed to upload, ' + error.reason,
              ...makeMessageClosable()
            })
          }
        }
      )
    },
    [mutate, goNext, form]
  )

  const defaultFile = useMemo(() => form.getFieldValue('file')?.file, [form])

  return (
    <div className={sx.root}>
      <div className={sx.fileWrapper}>
        <Form.Item name='file' valuePropName='fileList[0]'>
          <Dragger
            defaultFileList={defaultFile ? [defaultFile] : []}
            maxCount={1}
            disabled={isLoading}
            className={sx.file}
            customRequest={handleUpload}
            accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
          >
            {isLoading ? (
              <LoadSpinner />
            ) : (
              <>
                <UploadOutlined className={sx.icon} />
                Upload an Excel File
              </>
            )}
          </Dragger>
        </Form.Item>
        <p>
          *Note: you won't be able to change sheets or/and columns names after
          upload
        </p>
      </div>
      <Button
        size='large'
        hidden={defaultFile?.status !== 'success'}
        type='link'
        onClick={goNext}
        className={sx.btn}
        icon={<RightOutlined />}
      >
        To config edit
      </Button>
    </div>
  )
}
