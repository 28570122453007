type NewFetcherErrorParams = {
  request: XMLHttpRequest
  description?: string
}

export class FetcherError<ErrorPayload = any> {
  reqDescription: string
  reason: string
  code: number
  request: XMLHttpRequest
  payload?: ErrorPayload

  constructor({ request, description }: NewFetcherErrorParams) {
    let { response } = request

    let reason = 'unknown'

    if (typeof response === 'string' && response.length) {
      try {
        response = JSON.parse(response)

        if (request.status >= 400) {
          reason = 'incorrect request'
        }

        if (request.status >= 500) {
          reason = 'server malfunction'
        }

        if ('err_msg' in response) {
          reason = response.err_msg
        }

        if ('failed_msg' in response) {
          reason = response.failed_msg
        }

        if ('err_payload' in response) {
          this.payload = response.err_payload
        }
      } catch {
        reason = 'unknown due to non-JSON response format'
      }
    }

    this.reason = reason
    this.reqDescription = description || 'last action'
    this.code = 'status' in request ? request.status : 520
    this.request = request
  }
}
