import { useMutation, useQueryClient } from 'react-query'
import { message } from 'antd'

import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { Spreadsheet } from '@/entities/Spreadsheet'
import { SheetConfig } from '@/entities/Sheet'
import { makeMessageClosable } from '@/shared/lib/message'
import { SheetsWrapped } from '@/entities/Sheet/api/mutations/lib'

type UpdateSheetsPayload = SheetsWrapped<SheetConfig>

export const useUpdateSheetsMutation = () => {
  const qClient = useQueryClient()

  return useMutation<Spreadsheet, FetcherError, UpdateSheetsPayload>(
    async (payload) => {
      const { spreadsheet_id } = payload.sheets[0]
      const response = await fetcher<UpdateSheetsPayload, Spreadsheet>({
        method: 'put',
        description: 'Update sheet',
        params: {
          on_validation_err: 'RAISE_ALL_ERRORS'
        },
        url: `/spreadsheets/${spreadsheet_id}/sheets`,
        data: payload
      })

      return response.data
    },
    {
      onSuccess: (freshSpreadsheet, { sheets }) => {
        message.success(
          `Sheets ${sheets
            .map((sheet) => sheet.name)
            .join(', ')} were successfully updated`
        )

        qClient.setQueryData<Spreadsheet>(
          spreadsheetKeys.withId(freshSpreadsheet.id),
          freshSpreadsheet
        )
      },
      onError: (error) => {
        message.error({
          content: 'Sheet was not updated, reason : ' + error.reason,
          ...makeMessageClosable()
        })
      }
    }
  )
}
