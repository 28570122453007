import { FC } from 'react'
import { ChangeRecord, ChangeRecordList } from '@/shared/lib/types'

const CodeBlock: FC<{ code: string }> = ({ code }) => (
  <pre>{code.replace(/\n\s{8}/gm, '\n  ')}</pre>
)

const diffOrNull = (code: string | null | undefined, index: number) =>
  code ? <CodeBlock key={index + code.slice(5, 10)} code={code} /> : null

export const diffToCodeBlock = (record: ChangeRecord) =>
  [record.name, record.sql, record.validation_commands].map(diffOrNull)

export const isSingleDiff = (
  record: ChangeRecord | ChangeRecordList
): record is ChangeRecord => !('sheets' in record)
