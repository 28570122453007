import React, { FC } from 'react'
import { Button, message } from 'antd'
import { FileAddOutlined } from '@ant-design/icons'

import { useCreateSpreadsheetMutation } from '@/entities/Spreadsheet'
import { makeMessageClosable } from '@/shared/lib/message'
import { useNavigate } from 'react-router-dom'

type Props = {
  mutationBag: ReturnType<typeof useCreateSpreadsheetMutation>
  name: string
}

export const CreateButton: FC<Props> = ({ name, mutationBag }) => {
  const navigate = useNavigate()

  const { mutate, isLoading } = mutationBag
  const createBlankSpreadsheet = () =>
    mutate(
      { name },
      {
        onSuccess(newSpreadsheet) {
          navigate(newSpreadsheet.id)
          message.success({
            content: 'Created a spreadsheet with name ' + newSpreadsheet.name,
            duration: 3,
            ...makeMessageClosable()
          })
        }
      }
    )

  return (
    <Button
      type='ghost'
      block
      icon={<FileAddOutlined />}
      loading={isLoading}
      onClick={createBlankSpreadsheet}
    >
      Create spreadsheet
    </Button>
  )
}
