import { useMutation, useQueryClient } from 'react-query'
import { appConfig } from '@/shared/config/appConfig'
import { fetcher } from '@/shared/lib/fetcher/config'
import { CreateAppJWTResponse } from '../../shared/api/types'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { persistAppJWT } from '../../shared/lib'
import { viewerKeys } from '@/entities/Viewer'
import { showDefaultErrorMessage } from '@/shared/lib/message'

export const useGoogleLoginMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<
    CreateAppJWTResponse,
    FetcherError,
    google.CredentialResponse
  >(
    async ({ credential }) => {
      const response = await fetcher<CreateAppJWTResponse>({
        description: 'Create App JWT',
        url: '/create_app_jwt',
        headers: {
          'BluGrid-UI-App-Client-ID': appConfig.appClientId,
          'BluGrid-Google-JWT': credential
        }
      })
      return response.data
    },
    {
      onSuccess({ app_jwt, auth_id }) {
        persistAppJWT(app_jwt)
        queryClient.setQueryData(viewerKeys.account, auth_id)
      },
      onError: showDefaultErrorMessage
    }
  )
}
