import { useCallback, useState } from 'react'
import set from 'lodash.set'

export const useComplexState = <S extends object>(defState?: S) => {
  const [state, setState] = useState<S>(defState as S)

  const setStatePart = useCallback(
    (path: string | Array<string | number>, value: any) =>
      setState((prevState) => {
        const newState = { ...prevState }
        set(newState, path, value)
        return newState
      }),
    []
  )

  return [state, setStatePart] as const
}
