import { useDeleteRowsMutation } from '@/features/table/deleteRow/api/useDeleteRowsMutation'
import { Sheet } from '@/entities/Sheet'

type MutateFn = ReturnType<typeof useDeleteRowsMutation>['mutate']

type DeleteHandlerParams = {
  rowData: any
  mutate: MutateFn
  sheet: Sheet
}

export const deleteRowHandler = ({
  rowData,
  mutate,
  sheet
}: DeleteHandlerParams) => {
  const row_primary_id: any = {}

  for (const colConfig of sheet.columns) {
    if (colConfig.is_primary)
      row_primary_id[colConfig.name] = rowData[colConfig.name]
  }

  mutate({
    sheet,
    rowsDataSet: {
      rows: [{ row_data: rowData }]
    }
  })
}
