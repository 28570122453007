import { useCallback } from 'react'

import { Sheet } from '@/entities/Sheet'
import { useCreateRowsMutation } from '@/features/table/createRows'
import { normalizeRowValues } from '@/features/table/createRows/lib'

type Mutate = ReturnType<typeof useCreateRowsMutation>['mutate']

export const useCreationCallback = (mutate: Mutate, sheet: Sheet) =>
  useCallback(
    (newRowsArr: { rows: object[] }) => {
      const normalizedRows = newRowsArr.rows.map((row) => ({
        row_data: normalizeRowValues(sheet.columns, row)
      }))
      const rowsDataSet = {
        rows: normalizedRows
      }
      mutate({ sheet, rowsDataSet })
    },
    [mutate, sheet]
  )
