import React, { FC, useCallback } from 'react'
import { Modal } from 'antd'
import { DeleteFilled } from '@ant-design/icons'
import { useDeleteSpreadsheetMutation } from '@/entities/Spreadsheet/api/mutations'
import { SpreadsheetIdentifiers } from '@/entities/Spreadsheet'

type Props = {
  spreadsheet: SpreadsheetIdentifiers
}

export const DeleteSpreadsheet: FC<Props> = ({ spreadsheet }) => {
  const { mutateAsync } = useDeleteSpreadsheetMutation()
  const deleteSpreadSheetFn = useCallback(
    () => mutateAsync(spreadsheet.id),
    [mutateAsync, spreadsheet.id]
  )
  const openConfirmFn = useCallback(
    () =>
      Modal.confirm({
        content: ' This cannot be undone. Are you sure?',
        title: `You are going to delete the "${spreadsheet.name}" spreadsheet`,
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: deleteSpreadSheetFn,
        cancelText: 'Cancel',
        closable: true
      }),
    [deleteSpreadSheetFn, spreadsheet.name]
  )

  return <DeleteFilled size={50} onClick={openConfirmFn} />
}
