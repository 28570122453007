import { useSpreadsheetParams } from '@/shared/lib/hooks'
import { FormInstance } from 'antd'
import { useEffect } from 'react'

export const useResetEffect = (form: FormInstance) => {
  const { sheet_name } = useSpreadsheetParams()
  // suppressed not to write unnecessary wrapper function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(form.resetFields, [form, sheet_name])
}
