import React, { PropsWithChildren } from 'react'
import { Drawer } from 'antd'
import { UseMutationResult } from 'react-query'

import { useBooleanState } from '@/shared/lib/hooks/useBooleanState'
import { Sheet, SheetConfig } from '@/entities/Sheet'
import { DeleteSheet } from '@/features/sheet/delete'

import {
  DrawerStateContext,
  PerformMutationContext,
  VerifyMutationContext
} from './model'
import { ConfigSheetSteps } from './ConfigSheetSteps'

type Props = {
  sheet: SheetConfig | Sheet
  verifyMutationBag: UseMutationResult
  performMutationBag: UseMutationResult
}

export const ConfigSheetDrawer = ({
  sheet,
  verifyMutationBag,
  performMutationBag,
  children
}: PropsWithChildren<Props>) => {
  const drawerState = useBooleanState()
  const [isOpen, , open, close] = drawerState

  return (
    <>
      <div onClick={open}>{children}</div>
      <Drawer
        destroyOnClose
        title='Sheet configuration'
        visible={isOpen}
        onClose={close}
        width='clamp(50vw, 1000px, 100vw)'
        extra={'id' in sheet ? <DeleteSheet sheet={sheet} /> : null}
      >
        <DrawerStateContext.Provider value={drawerState}>
          <PerformMutationContext.Provider value={performMutationBag}>
            <VerifyMutationContext.Provider value={verifyMutationBag}>
              <ConfigSheetSteps sheet={sheet} />
            </VerifyMutationContext.Provider>
          </PerformMutationContext.Provider>
        </DrawerStateContext.Provider>
      </Drawer>
    </>
  )
}
