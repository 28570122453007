import React, { FC } from 'react'
import { UseMutationResult } from 'react-query'

import {
  ConfigSheetDrawer,
  Sheet,
  useUpdateSheetsMutation
} from '@/entities/Sheet'
import { useConfigVerifyMutation } from './api'

type Props = {
  sheet: Sheet
}

export const ConfigureSheet: FC<Props> = ({ children, sheet }) => {
  const verifyMutationBag = useConfigVerifyMutation() as UseMutationResult
  const performMutationBag = useUpdateSheetsMutation() as UseMutationResult

  return (
    <ConfigSheetDrawer
      sheet={sheet}
      verifyMutationBag={verifyMutationBag}
      performMutationBag={performMutationBag}
    >
      {children}
    </ConfigSheetDrawer>
  )
}
