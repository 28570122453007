import { useCallback, useMemo, useState } from 'react'

export const useStepState = () => {
  const [currentStep, setCurrentStep] = useState(0)

  const goNext = useCallback(() => setCurrentStep((step) => step + 1), [])
  const goPrev = useCallback(() => setCurrentStep((step) => step - 1), [])

  return useMemo(
    () => [currentStep, goPrev, goNext, setCurrentStep] as const,
    [currentStep, goNext, goPrev]
  )
}
