import { useMutation, useQueryClient } from 'react-query'
import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { SpreadsheetIdentifiers } from '@/entities/Spreadsheet'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { showDefaultErrorMessage } from '@/shared/lib/message'

export type CreateSpreadsheetParams = { name: string }

export const useCreateSpreadsheetMutation = () => {
  const qClient = useQueryClient()

  return useMutation<
    SpreadsheetIdentifiers,
    FetcherError,
    CreateSpreadsheetParams
  >(
    async (spreadsheetData) => {
      const response = await fetcher<
        CreateSpreadsheetParams,
        SpreadsheetIdentifiers
      >({
        method: 'post',
        description: 'Create a spreadsheet',
        url: '/spreadsheets',
        data: spreadsheetData
      })
      return response.data
    },
    {
      onSuccess: (data) => {
        qClient.setQueryData<SpreadsheetIdentifiers[]>(
          spreadsheetKeys.list,
          (oldList) => [...(oldList || []), data]
        )
      },
      onError: showDefaultErrorMessage
    }
  )
}
