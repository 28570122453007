import React from 'react'
import { DatePicker, Input, InputNumber, Select } from 'antd'
import { CaretDownFilled } from '@ant-design/icons'

import { CellMetadata } from '@/entities/Sheet'
import { Column, ColumnValueType } from '@/entities/Column'
import { cx } from '@/shared/lib/classnames'

import sx from './styles.less'

const { Boolean, Double, Integer, Date } = ColumnValueType

export const getInputByColumnType = (
  column: Column,
  metadata?: CellMetadata,
  focus?: boolean
) => {
  let component: JSX.Element
  const { options, disabled_msg, err_msg } = metadata || {}

  switch (column.column_type) {
    case Boolean:
      component = (
        <Select
          status={err_msg ? 'error' : ''}
          key={column.name}
          disabled={!!disabled_msg}
          autoFocus={focus}
          showArrow={false}
          allowClear
          className={cx(sx.input, sx.boolean)}
          dropdownClassName={sx.booleanDropdown}
          optionLabelProp='label'
          options={[
            { value: 1, label: '✓' },
            { value: 0, label: '✗' }
          ]}
        />
      )
      break

    case Double:
    case Integer:
      component = (
        <InputNumber
          key={column.name}
          disabled={!!disabled_msg}
          className={cx(sx.input, sx.number)}
          autoFocus={focus}
        />
      )
      break

    case Date: {
      component = (
        <DatePicker
          key={column.name}
          disabled={!!disabled_msg}
          className={sx.input}
          allowClear
          autoFocus={focus}
        />
      )
      break
    }

    default:
      component = (
        <Input
          key={column.name}
          disabled={!!disabled_msg}
          className={sx.input}
          allowClear
          autoFocus={focus}
        />
      )
  }

  if (options?.length) {
    component = (
      <Select
        key={column.name}
        disabled={!!disabled_msg}
        allowClear={!column.required}
        className={cx(sx.input, sx.select)}
        options={options.map((value: string) => ({
          value,
          label: value
        }))}
        suffixIcon={<CaretDownFilled />}
      />
    )
  }

  return component
}
