import React, { FC } from 'react'
import { Button, Form, FormInstance } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { useGetNewRowsMetadataMutation } from '@/features/table/createRows/api/useGetNewRowsMetadataMutation'
import { Sheet } from '@/entities/Sheet'

import { getInputByColumnType, getMetaIndicators } from './ui'
import {
  useResetEffect,
  useReadMetadata,
  useCreateEmptyRowState,
  useCreationCallback,
  useRequestMetadataCallBack,
  CreateRowFormValues,
  defineTrigger
} from './lib'
import { useCreateRowsMutation } from '../../api'

import sx from './styles.less'

type Props = {
  form: FormInstance
  sheet: Sheet
  metadataMutationBag: ReturnType<typeof useGetNewRowsMetadataMutation>
  creationMutationBag: ReturnType<typeof useCreateRowsMutation>
}

const validateMessages = {
  required: 'Required'
}

export const RowCreationForm: FC<Props> = ({
  sheet,
  form,
  creationMutationBag,
  metadataMutationBag
}) => {
  useResetEffect(form)

  const metadata = useReadMetadata()

  const createRows = useCreationCallback(creationMutationBag.mutate, sheet)

  const emptyRow = useCreateEmptyRowState(sheet)

  const requestMetadata = useRequestMetadataCallBack(
    form,
    metadataMutationBag.mutate,
    sheet
  )

  return (
    <Form<CreateRowFormValues>
      form={form}
      onValuesChange={requestMetadata}
      validateMessages={validateMessages}
      onFinish={createRows}
      className={sx.form}
      layout='inline'
      size='middle'
      preserve={false}
      labelWrap
    >
      <Form.List name='rows' initialValue={[emptyRow]}>
        {(rows, { add, remove }) =>
          rows.map(({ key, name }, rowIndex, rowsArr) => (
            <div key={key} className={sx.rowContainer}>
              {rowIndex !== 0 ? (
                <DeleteOutlined
                  key='deleteButton'
                  className={sx.actionBtn}
                  onClick={() => remove(name)}
                />
              ) : (
                <PlusCircleOutlined
                  key='addButton'
                  className={sx.actionBtn}
                  onClick={() => add(emptyRow)}
                />
              )}
              {sheet.columns.map((col, colIndex, colArr) => (
                <div className={sx.formItemWrapper} key={col.name}>
                  <Form.Item
                    colon={false}
                    trigger={defineTrigger(
                      col,
                      !!metadata?.[rowIndex][col.name].options?.length,
                      !rowsArr[rowIndex + 1] && !colArr[colIndex + 1]
                    )}
                    name={[name, col.name]}
                    required={col.required}
                    label={col.name}
                    key={col.name}
                  >
                    {getInputByColumnType(
                      col,
                      metadata?.[rowIndex][col.name],
                      rowIndex === rows.length - 1 && colIndex === 0
                    )}
                  </Form.Item>
                  {getMetaIndicators(metadata?.[rowIndex][col.name])}
                </div>
              ))}
            </div>
          ))
        }
      </Form.List>
      {/*Allows submitting by pressing Enter*/}
      <Button hidden htmlType='submit' />
    </Form>
  )
}
