import React from 'react'
import { CaretDownFilled } from '@ant-design/icons'
import { ColDef } from 'ag-grid-enterprise'

import { ColumnValueType, hasColumnTypes } from '@/entities/Column'

import sx from '../styles.less'

const { Boolean } = ColumnValueType

type ValueNormalisationParams = {
  value: any
  colDef?: ColDef
  isDropdown: boolean
}

export const getValueToDisplay = ({
  colDef,
  value,
  isDropdown
}: ValueNormalisationParams) => {
  if (!colDef) return value
  const { type: colTypes } = colDef
  if (!hasColumnTypes(colTypes)) return value

  if (isDropdown)
    return (
      <div className={sx.dropdown}>
        <CaretDownFilled />
        {value}
      </div>
    )

  const [baseType] = colTypes
  switch (baseType) {
    case Boolean:
      if (value === null) return ''
      return value ? '✓' : '✗'
    default:
      return value
  }
}
