import { hasColumnTypes } from '@/entities/Column'
import { CellMetadata } from '@/entities/Sheet'
import { ColDef } from 'ag-grid-enterprise'

type MetadataDetectionPayload = {
  data: any
  colDef?: ColDef
}

export const getCellMetadata = ({
  data,
  colDef
}: MetadataDetectionPayload): CellMetadata | undefined => {
  if (!colDef || !hasColumnTypes(colDef.type)) return

  return data?.__bg_meta?.columns[colDef.field || '']
}
