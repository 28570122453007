import React, { FC, useCallback } from 'react'
import { useDeleteSheetsMutation } from '@/entities/Sheet/api/mutations/useDeleteSheetsMutation'
import { Button, ButtonProps, Modal } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'
import { Sheet } from '@/entities/Sheet'
import { useNavigate } from 'react-router-dom'

type Props = {
  sheet: Sheet
  buttonProps?: ButtonProps
}

export const DeleteSheet: FC<Props> = ({ sheet, buttonProps }) => {
  const navigate = useNavigate()
  const { mutateAsync, isLoading } = useDeleteSheetsMutation()
  const deleteSheetFn = useCallback(
    () =>
      mutateAsync(
        {
          sheets: [{ id: sheet.id, name: sheet.name }],
          spreadsheet_id: sheet.spreadsheet_id
        },
        {
          onSuccess() {
            navigate('../')
          }
        }
      ),
    [mutateAsync, navigate, sheet.id, sheet.spreadsheet_id, sheet.name]
  )
  const onIconClick = useCallback<React.MouseEventHandler>(
    (event) => {
      event.preventDefault()
      Modal.confirm({
        content: 'This cannot be undone. Are you sure?',
        title: `You are going to delete the "${sheet.name}" sheet`,
        width: 600,
        okText: 'Delete',
        okType: 'danger',
        onOk: deleteSheetFn,
        cancelText: 'Cancel',
        closable: true
      })
    },
    [deleteSheetFn, sheet.name]
  )

  return (
    <Button
      onClick={onIconClick}
      loading={isLoading}
      type='link'
      danger
      icon={<DeleteOutlined />}
      {...buttonProps}
    >
      Delete
    </Button>
  )
}
