import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tooltip, Typography } from 'antd'
import { LeftOutlined } from '@ant-design/icons'

import sx from '../styles.less'

type Props = {
  spreadsheetName: string
}

export const TableHeadContent: FC<Props> = ({ spreadsheetName }) => {
  const navigate = useNavigate()
  const goToHomePage = useCallback(() => navigate('/'), [navigate])

  return (
    <>
      <Tooltip title='Spreadsheets list' placement='bottomRight'>
        <LeftOutlined className={sx.arrow} onClick={goToHomePage} />
      </Tooltip>

      <Typography.Title className={sx.title} level={2}>
        {spreadsheetName}
      </Typography.Title>
    </>
  )
}
