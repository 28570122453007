import { useSpreadsheetParams } from '@/shared/lib/hooks'
import { CreateSheetPopover } from '@/widgets/sheet/CreateSheetPopover'
import React, { FC, useCallback, useMemo } from 'react'
import { Radio, RadioChangeEvent, Skeleton } from 'antd'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import { Spreadsheet, spreadsheetKeys } from '@/entities/Spreadsheet'

import { SheetTab } from './ui'

import sx from './styles.less'

export const SheetsPanel: FC = () => {
  const { sheet_name, ss_id } = useSpreadsheetParams()

  const qClient = useQueryClient()
  const spreadsheet = qClient.getQueryData<Spreadsheet>(
    spreadsheetKeys.withId(ss_id)
  )

  const navigate = useNavigate()
  const switchToTab = useCallback(
    (e: RadioChangeEvent) => navigate(`../${e.target.value}`),
    [navigate]
  )

  const sheets = useMemo(
    () =>
      spreadsheet?.sheets.map((sheet) => ({
        label: <SheetTab sheet={sheet} />,
        value: sheet.name
      })),
    [spreadsheet?.sheets]
  )

  if (!spreadsheet) return <Skeleton.Button block active />

  return (
    <div className={sx.root}>
      <CreateSheetPopover spreadsheet={spreadsheet}>
        <PlusOutlined className={sx.addSheetIcon} />
      </CreateSheetPopover>
      <div className={sx.scrollContainer}>
        <Radio.Group
          className={sx.sheets}
          options={sheets}
          value={sheet_name}
          optionType='button'
          buttonStyle='solid'
          size='large'
          onChange={switchToTab}
        />
      </div>
    </div>
  )
}
