import React, { ChangeEventHandler, FC, useCallback, useState } from 'react'
import { Input } from 'antd'

import { useCreateSpreadsheetMutation } from '@/entities/Spreadsheet'

import { CreateButton } from './CreateButton'

import sx from './styles.less'

export const CreateBlankSpreadsheetForm: FC = () => {
  const [name, setName] = useState('')

  const onNameChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (event) => setName(event.target.value),
    []
  )

  const mutationBag = useCreateSpreadsheetMutation()

  return (
    <div className={sx.form}>
      <label>
        Name:
        <Input
          allowClear
          className={sx.nameInput}
          value={name}
          onChange={onNameChange}
        />
      </label>

      <CreateButton name={name} mutationBag={mutationBag} />
    </div>
  )
}
