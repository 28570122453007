import { getCellMetadata } from '@/entities/Sheet/lib'
import { CaretDownFilled } from '@ant-design/icons'
import React, {
  FC,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { DatePicker, Input, InputNumber, Select } from 'antd'
import { ICellEditorParams } from 'ag-grid-community'
import moment from 'moment'

import { ColumnValueType, hasColumnTypes } from '@/entities/Column'
import { useBooleanState } from '@/shared/lib/hooks'
import { cx } from '@/shared/lib/classnames'

import { RequiredIndicator } from './RequiredIndicator'

import sx from './styles.less'

const { Boolean, Double, Integer, Date, DateTime } = ColumnValueType

type Props = ICellEditorParams

export const CellEditor: FC<Props> = forwardRef(
  (
    { value: initialValue, colDef, data, stopEditing, eventKey },
    forwardedRef
  ) => {
    const [isTouched, , setTouched] = useBooleanState()
    const [doesSubmitOnChange, , setSubmitOnChange] = useBooleanState()
    const [value, setValue] = useState<any>(initialValue)

    /**Required by AG Grid to pick the ; @see: https://www.ag-grid.com/react-data-grid/component-cell-editor/#cell-editor-component-2*/
    useImperativeHandle(forwardedRef, () => ({
      isCancelBeforeStart() {
        return !!data?.__bg_meta?.columns[colDef?.field || '']?.disabled_msg
      },
      getValue: () => value
    }))

    /** Re-implements default `submit-on-Enter` AG Grid behaviour to change the order of callbacks fired on Enter press,
     * so that editors with menus - Select/Date(Time)Picker - can submit by pressing the Enter key */
    useEffect(() => {
      const stopEditOnEnter = (event: KeyboardEvent) => {
        if (event.key === 'Enter' && isTouched) return stopEditing()

        if (!isTouched) setTouched()
      }
      window.addEventListener('keydown', stopEditOnEnter)

      return () => window.removeEventListener('keydown', stopEditOnEnter)
    }, [isTouched, setTouched, stopEditing])

    /** Starts the value removal (if it's present) if Delete or Backspace were pressed on a non-required cell.
     Triggers the underlying useEffect that stops editing*/
    useEffect(() => {
      const metadata = getCellMetadata({ data, colDef })
      if (
        !metadata?.required_msg &&
        (eventKey === 'Delete' || eventKey === 'Backspace') &&
        initialValue !== null
      ) {
        setValue(null)
        setTouched()
        setSubmitOnChange()
      }
    }, [
      colDef,
      data,
      eventKey,
      initialValue,
      setSubmitOnChange,
      setTouched,
      stopEditing
    ])

    /** Allows menu-driven components to trigger submit instantly after value change */
    useEffect(() => {
      if (doesSubmitOnChange) {
        if (value !== initialValue) {
          stopEditing()
        }
      }
    }, [doesSubmitOnChange, initialValue, stopEditing, value])

    if (!colDef) return initialValue
    const { type: colTypes } = colDef
    if (!hasColumnTypes(colTypes)) return initialValue

    let inputComponent: JSX.Element
    const [baseType, isRequired] = colTypes

    switch (baseType) {
      case Boolean: {
        !doesSubmitOnChange && setSubmitOnChange()
        inputComponent = (
          <Select
            defaultOpen
            defaultValue={value}
            allowClear
            autoFocus
            showArrow={false}
            onChange={setValue}
            optionLabelProp='label'
            className={cx(sx.input, sx.select, sx.boolean)}
            dropdownClassName={sx.booleanDropdown}
            options={[
              { value: 1, label: '✓' },
              { value: 0, label: '✗' }
            ]}
          />
        )
        break
      }

      case Double:
      case Integer:
        inputComponent = (
          <InputNumber
            autoFocus
            className={cx(sx.input, sx.number)}
            onChange={setValue}
            value={value}
          />
        )
        break

      case Date:
      case DateTime: {
        !doesSubmitOnChange && setSubmitOnChange()
        const defDate = moment(initialValue)
        //TODO: reimplement with https://github.com/Hacker0x01/react-datepicker/ to enable keyboard navigation
        inputComponent = (
          <DatePicker
            allowClear={!isRequired}
            defaultOpen
            defaultValue={defDate.isValid() ? defDate : undefined}
            bordered={false}
            onChange={(e) => setValue(e?.format('YYYY-MM-DD') || null)}
          />
        )
        break
      }

      default:
        inputComponent = (
          <Input
            allowClear={!isRequired}
            autoFocus
            className={sx.input}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
        )
    }

    const dropdownOptions = getCellMetadata({ data, colDef })?.options
    if (dropdownOptions?.length) {
      !doesSubmitOnChange && setSubmitOnChange()
      inputComponent = (
        <Select
          allowClear={!isRequired}
          className={cx(sx.input, sx.select)}
          defaultValue={value}
          defaultOpen
          autoFocus
          onChange={setValue}
          options={dropdownOptions.map((value: string) => ({
            value,
            label: value
          }))}
          suffixIcon={<CaretDownFilled />}
        />
      )
    }

    const metadata = getCellMetadata({ data, colDef })

    return metadata?.required_msg ? (
      <RequiredIndicator message={metadata.required_msg}>
        {inputComponent}
      </RequiredIndicator>
    ) : (
      inputComponent
    )
  }
)
