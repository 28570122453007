import { Tooltip } from 'antd'
import React, { FC } from 'react'

import sx from './styles.less'

type Props = {
  message: string
}

export const RequiredIndicator: FC<Props> = ({ children, message }) => {
  return (
    <div className={sx.container}>
      <Tooltip title={message} color='#ff4d4f'>
        <div className={sx.indicator}>*</div>
      </Tooltip>
      {children}
    </div>
  )
}
