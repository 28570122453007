import { ColumnTypes } from '@/entities/Column'
import { useCallback } from 'react'
import { useQueryClient } from 'react-query'
import { message } from 'antd'

import { Sheet } from '@/entities/Sheet'
import {
  makeMessageClosable,
  showDefaultErrorMessage
} from '@/shared/lib/message'
import { normalizeCellValue } from '@/features/table/setCellValue/model/normalizeCellValue'
import { CellEditRequestEvent } from 'ag-grid-community'
import { sheetKeys } from '@/entities/Sheet/model'

import { useCellMutation } from '../api'

export const useEditCellHandler = (sheet: Sheet) => {
  const qClient = useQueryClient()
  const { mutate } = useCellMutation()
  return useCallback(
    (event: CellEditRequestEvent) => {
      let checkedValue: any = null
      try {
        checkedValue =
          normalizeCellValue(
            event.newValue,
            event.colDef.type as ColumnTypes
          ) ?? null
      } catch (e) {
        if (e instanceof Error) {
          message.error({
            content: e.message,
            duration: 5,
            ...makeMessageClosable()
          })
        }

        return
      }

      if (event.oldValue === checkedValue) return

      const column_id = event.column.getId()

      const columnName = event.colDef.field!

      //TODO: when rowId is implemented, optimize value update by updating row and now the whole table

      // const setResultingCellValue = (value: any) =>
      //   qClient.setQueryData<any[]>(
      //     sheetKeys.dataOf(sheet.spreadsheet_id, sheet.id),
      //     (sheetData) => {
      //       if (!sheetData) return []
      //       sheetData[event.node.rowIndex!][columnName] = value
      //       return [...sheetData]
      //     }
      //   )

      //optimistically update the value before the request
      event.node.setDataValue(column_id, checkedValue)

      mutate(
        {
          params: {
            rows: [
              {
                column_names: [columnName],
                row_data: {
                  ...event.data,
                  [columnName]: checkedValue
                },
                curr_row_data: {
                  ...event.data,
                  [columnName]: event.oldValue
                }
              }
            ]
          },
          sheet: sheet
        },
        {
          onSuccess() {
            message.success({
              content: `Updated with value: ${checkedValue}`,
              duration: 1
            })
            qClient.invalidateQueries(
              sheetKeys.dataOf(sheet.spreadsheet_id, sheet.id)
            )
            // setResultingCellValue(checkedValue)
          },
          onError(error) {
            event.node.setDataValue(column_id, event.oldValue)
            showDefaultErrorMessage(error)
          }
        }
      )
    },
    [sheet, mutate, qClient]
  )
}
