import { useQuery } from 'react-query'
import { ViewerAuthInfo, viewerKeys } from '@/entities/Viewer/model'
import { fetcher } from '@/shared/lib/fetcher/config'
import { APP_JWT_KEY_NAME } from '../../lib'
import { freshForever } from '@/shared/lib/query'
import { FetcherError } from '@/shared/lib/fetcher'
import { eraseAppJWT } from '@/features/auth/logout/lib'
import { showDefaultErrorMessage } from '@/shared/lib/message'

export const useValidateAppKeyQuery = () =>
  useQuery(
    viewerKeys.account,
    async ({ signal }) => {
      const appJWT = localStorage.getItem(APP_JWT_KEY_NAME)

      if (!appJWT || appJWT === 'undefined' || appJWT === 'null') return null

      const response = await fetcher<ViewerAuthInfo>({
        description: 'Verify JWT authentication key',
        url: '/verify_auth',
        headers: {
          'BluGrid-App-JWT': appJWT
        },
        signal
      })

      return response.data.auth_id
    },
    {
      ...freshForever,
      onError: (err: FetcherError) => {
        if (err.code >= 400 && err.code < 500) {
          showDefaultErrorMessage(err)
          eraseAppJWT()
        }
      }
    }
  )
