import { useMutation, useQueryClient } from 'react-query'
import { message } from 'antd'

import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { Spreadsheet } from '@/entities/Spreadsheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'
import { Sheet } from '@/entities/Sheet'

import { SheetsWrapped } from './lib'

type DeleteSheetPayload = SheetsWrapped<Pick<Sheet, 'id' | 'name'>>

type DeleteSheetParams = DeleteSheetPayload & Pick<Sheet, 'spreadsheet_id'>

export const useDeleteSheetsMutation = () => {
  const qClient = useQueryClient()

  return useMutation<Spreadsheet, FetcherError, DeleteSheetParams>(
    async ({ spreadsheet_id, sheets }) => {
      const response = await fetcher<DeleteSheetPayload, Spreadsheet>({
        method: 'delete',
        description: 'Delete sheets',
        url: `/spreadsheets/${spreadsheet_id}/sheets`,
        data: {
          sheets
        }
      })

      return response.data
    },
    {
      onSuccess: (spreadsheet, { sheets }) => {
        message.success(
          `Deleted sheets with names: ${sheets
            .map((sheet) => sheet.name)
            .join(', ')}`
        )

        qClient.setQueryData<Spreadsheet>(
          spreadsheetKeys.withId(spreadsheet.id),
          spreadsheet
        )
      },
      onError: showDefaultErrorMessage
    }
  )
}
