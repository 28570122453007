import React, { CSSProperties, FC } from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import { cx } from '@/shared/lib/classnames'

import sx from './styles.less'

type Props = {
  className?: string
  style?: CSSProperties
  size?: 1 | 2 | 3
}

export const LoadSpinner: FC<Props> = ({ className, style, size = 1 }) => (
  <div className={cx(sx.spinner, className)}>
    <LoadingOutlined style={{ fontSize: `${1.5 * size}em`, ...style }} />
  </div>
)
