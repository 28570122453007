import React, { FC } from 'react'
import { appConfig } from '@/shared/config/appConfig'
import { Layout, Popover, Typography } from 'antd'
import { UpOutlined } from '@ant-design/icons'

import sx from './styles.less'
import { useBooleanState } from '@/shared/lib/hooks/useBooleanState'
import { Link } from 'react-router-dom'
import { ViewerAvatar } from '@/entities/Viewer'
import { LogoutButton } from '@/features/auth'
import { cx } from '@/shared/lib/classnames'

const { Title } = Typography
const { Header } = Layout

type Props = {
  siderState: ReturnType<typeof useBooleanState>
}

export const AppHeader: FC<Props> = ({ siderState }) => {
  const [isHidden, toggleHidden] = useBooleanState()

  return (
    <div className={sx.root}>
      <Header className={cx(sx.header, isHidden && sx.hidden)}>
        {/*{isMenuOpen ? <MenuFoldOutlined className={sx.menuButton}*/}
        {/*                                onClick={toggleMenu}/> : <MenuUnfoldOutlined className={sx.menuButton}*/}
        {/*                                                                             onClick={toggleMenu}/>}*/}
        <div className={sx.headerContent}>
          <Link to='/'>
            <Title className={sx.title} level={1}>
              BluGrid{appConfig.is_prod() ? '' : `\u00A0(${appConfig.env})`}
            </Title>
          </Link>
          <Popover
            trigger='click'
            content={<LogoutButton />}
            placement='bottomLeft'
          >
            <div>
              <ViewerAvatar />
            </div>
          </Popover>
        </div>
      </Header>
      <div className={sx.heightToggle} onClick={toggleHidden}>
        <UpOutlined rotate={isHidden ? 180 : 0} />
      </div>
    </div>
  )
}
