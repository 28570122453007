import { useMutation, useQueryClient } from 'react-query'
import { message } from 'antd'

import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { Spreadsheet } from '@/entities/Spreadsheet'
import { Sheet, SheetConfig } from '@/entities/Sheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'
import { SheetsWrapped } from '@/entities/Sheet/api/mutations/lib'

type CreateSheetsPayload = SheetsWrapped<SheetConfig>

type CreateSheetsResponse = SheetsWrapped<Sheet>

export const useCreateSheetsMutation = () => {
  const qClient = useQueryClient()

  return useMutation<CreateSheetsResponse, FetcherError, CreateSheetsPayload>(
    async (payload) => {
      const { spreadsheet_id } = payload.sheets[0]

      const formData = new FormData()
      const json = JSON.stringify(payload)
      const blob = new Blob([json], {
        type: 'application/json'
      })
      formData.append('json_data', blob)

      const response = await fetcher<FormData, CreateSheetsResponse>({
        method: 'post',
        description: 'Create sheet',
        url: `/spreadsheets/${spreadsheet_id}/sheets`,
        params: {
          create_type: 'JSON_CONFIG'
        },
        data: formData
      })

      return response.data
    },
    {
      onSuccess: ({ sheets: createdSheets }) => {
        message.success(
          `Sheets "${createdSheets
            .map((sheet) => sheet.name)
            .join(', ')}" were created successfully`
        )

        qClient.setQueryData<Spreadsheet>(
          spreadsheetKeys.withId(createdSheets[0].spreadsheet_id),
          (oldSpreadsheet) => {
            if (!oldSpreadsheet) return {} as Spreadsheet

            const newSpreadsheet = { ...oldSpreadsheet }
            newSpreadsheet.sheets = newSpreadsheet.sheets.concat(createdSheets)
            return newSpreadsheet
          }
        )
      },
      onError: showDefaultErrorMessage
    }
  )
}
