import React, { FC } from 'react'
import {
  useSheetConfigContext,
  useStepContext
} from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/model'
import { Button, Tooltip } from 'antd'

import { validateConfig } from './lib'
import { useVerifyMutationContext } from '@/entities/Sheet/ui/ConfigSheetDrawer/model'

import sx from './styles.less'

export const VerifyButton: FC = () => {
  const [sheet] = useSheetConfigContext()

  const { mutate, isLoading } = useVerifyMutationContext()
  const [, , goNext] = useStepContext()
  const clickHandler = () =>
    mutate(
      { sheets: [sheet] },
      {
        onSuccess: goNext
      }
    )
  const { isValid, error } = validateConfig(sheet)

  return (
    <Tooltip placement='left' overlay={error}>
      <Button
        disabled={!isValid}
        loading={isLoading}
        type='primary'
        className={sx.btn}
        onClick={clickHandler}
      >
        Verify configuration
      </Button>
    </Tooltip>
  )
}
