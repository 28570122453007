import React from 'react'
import { ConfigProvider, message, Spin } from 'antd'
import { HOWrapper } from '@/app/providers/types'
import { LoadSpinner } from '@/shared/ui/LoadSpinner'

Spin.setDefaultIndicator(<LoadSpinner />)

message.config({
  maxCount: 3
})

declare module 'antd' {
  interface MenuItemParams {
    label: React.ReactNode
    key: React.Key
    icon?: React.ReactNode
    children?: React.ReactNode[]
    type?: 'group'
  }
}

export const withAntd: HOWrapper = (component) => () =>
  <ConfigProvider>{component()}</ConfigProvider>
