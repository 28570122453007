import { useMutation } from 'react-query'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { fetcher } from '@/shared/lib/fetcher/config'
import { ChangeRecordList } from '@/shared/lib/types'
import { Spreadsheet } from '@/entities/Spreadsheet'

type Action = 'verify' | 'submit'

type ConfigUploadPayload = {
  db_id: string
  formData: FormData
  action: Action
}

type UploadResponse<A extends Action> = A extends 'verify'
  ? ChangeRecordList
  : Pick<Spreadsheet, 'sheets'>

export const useConfigUploadMutation = <A extends Action>() =>
  useMutation<UploadResponse<A>, FetcherError, ConfigUploadPayload>(
    //@ts-ignore
    async ({ db_id, formData, action }) => {
      const response = await fetcher<FormData, UploadResponse<A>>({
        method: 'post',
        description: `Upload File`,
        url: `/spreadsheets/${db_id}/sheets${
          action === 'verify' ? '_verify_config' : ''
        }`,
        params: {
          create_type: 'FILE_UPLOAD'
        },
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })

      return response.data
    }
  )
