import { ColumnTypes, ColumnValueType } from '@/entities/Column/model'

const { Double, Integer, Boolean } = ColumnValueType

const parseNumber = (value: string): number => {
  const parsed = parseFloat(value)
  if (isNaN(parsed))
    throw new Error(
      `Value "${value}" is not valid in this cell: number was expected`
    )
  return parsed
}

export const normalizeCellValue = (value: any, columnTypes: ColumnTypes) => {
  switch (columnTypes[0]) {
    case Double:
      return parseNumber(value)

    case Integer: {
      const parsed = parseNumber(value)
      if (parsed % 1)
        throw new Error(
          `Value "${value}" is not valid in this cell: integer was expected`
        )
      return parsed
    }

    case Boolean:
      //undefined case is specified due to antd setting value as `undefined` on input clear
      if (value === null || value === undefined) return null

      return value

    default:
      if (typeof value === 'string' && !value.length) return null

      return value
  }
}
