import { QueryClient, QueryClientProvider } from 'react-query'
import { HOWrapper } from '@/app/providers/types'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
})

export const withQueryClient: HOWrapper = (component) => () =>
  <QueryClientProvider client={queryClient}>{component()}</QueryClientProvider>
