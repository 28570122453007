import React, { FC, useCallback } from 'react'
import { Form } from 'antd'

import { FormProviderProps } from 'antd/es/form/context'
import { useSheetConfigContext } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/model'
import { ColumnEditorsMenu } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/ui/SheetConfigMenu/ui/SheetEditor/ColumnEditorsMenu'

export const SheetEditor: FC = () => {
  const [sheetConfig, setPart] = useSheetConfigContext()

  //@ts-ignore
  const onFormChange = useCallback<FormProviderProps['onFormChange']>(
    (formName, info) => {
      const colIndex = sheetConfig.columns.findIndex(
        (col) => col.name === formName
      )
      for (const change of info.changedFields) {
        const path = ['columns', colIndex, ...(change.name as Array<string>)]
        setPart(path, change.value)
      }
    },
    [setPart, sheetConfig.columns]
  )

  return (
    <Form.Provider onFormChange={onFormChange}>
      <ColumnEditorsMenu />
    </Form.Provider>
  )
}
