import { useSpreadsheetParams } from '@/shared/lib/hooks'
import React, { FC } from 'react'
import { Form } from 'antd'
import { UploadFile } from 'antd/es/upload/interface'

import { SheetsConfigsList } from '@/features/spreadsheet'
import { ChangeRecordList } from '@/shared/lib/types'
import { useStepState } from '@/shared/lib/hooks/useStepState'

import { StepContext } from './model'
import { FormSteps } from './ui'

export type FormValues = {
  db_id: string
  file?: File | UploadFile
  config?: SheetsConfigsList
  schemaDescription?: ChangeRecordList
}

export const CreateSheetsFromFileForm: FC = () => {
  const { ss_id } = useSpreadsheetParams()

  const [form] = Form.useForm<FormValues>()
  const stepState = useStepState()

  return (
    <StepContext.Provider value={stepState}>
      <Form<FormValues>
        form={form}
        initialValues={{
          db_id: ss_id
        }}
      >
        <FormSteps />
      </Form>
    </StepContext.Provider>
  )
}
