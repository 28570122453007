import { Sheet } from '@/entities/Sheet'
import { RowMetadata, sheetKeys } from '@/entities/Sheet/model'
import { CreationError } from './types'
import { fetcher } from '@/shared/lib/fetcher/config'
import { FetcherError } from '@/shared/lib/fetcher/error'
import {
  makeMessageClosable,
  showDefaultErrorMessage
} from '@/shared/lib/message'
import { message } from 'antd'
import set from 'lodash.set'
import { useMutation, useQueryClient } from 'react-query'
import { RowsDataSet } from '../../lib/types'

type CreateRowParams = {
  rowsDataSet: RowsDataSet
  sheet: Sheet
}

export const useCreateRowsMutation = () => {
  const qClient = useQueryClient()

  return useMutation<RowsDataSet, FetcherError<CreationError>, CreateRowParams>(
    async ({ sheet, rowsDataSet }) => {
      const response = await fetcher<RowsDataSet>({
        description: 'Create row(s)',
        method: 'post',
        params: {
          on_validation_err: 'RAISE_ALL_ERRORS'
        },
        url: `/spreadsheets/${sheet.spreadsheet_id}/sheets/${sheet.id}/data`,
        data: rowsDataSet
      })

      return response.data
    },
    {
      onSuccess(data, { sheet }) {
        message.success({
          content: 'Row(s) created!',
          ...makeMessageClosable()
        })
        qClient.invalidateQueries(
          sheetKeys.dataOf(sheet.spreadsheet_id, sheet.id)
        )
      },
      onError(error) {
        if (error.payload) {
          const { column_errors } = error.payload
          qClient.setQueryData<RowMetadata[]>(
            sheetKeys.rowsCreationMetadata(),
            (prevMeta) => {
              const newMeta = prevMeta || []

              column_errors.forEach(({ row_idx, column_name, err_msg }) =>
                set(newMeta, [row_idx, column_name, 'err_msg'], err_msg)
              )

              return newMeta
            }
          )
        }
        showDefaultErrorMessage(error)
      }
    }
  )
}
