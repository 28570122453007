import React, { FC, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button } from 'antd'

import { ChangeList } from '@/shared/ui/ChangeDashboard'
import {
  useDrawerStateContext,
  usePerformMutationContext,
  useVerifyMutationContext
} from '@/entities/Sheet/ui/ConfigSheetDrawer/model'
import { ChangeRecord, ChangeRecordList } from '@/shared/lib/types'
import {
  useSheetConfigContext,
  useStepContext
} from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/model'
import { LeftOutlined } from '@ant-design/icons'

import sx from './styles.less'

export const ChangesComparison: FC = () => {
  const [, goPrev] = useStepContext()

  const navigate = useNavigate()
  const { mutate, isLoading } = usePerformMutationContext()
  const [sheet] = useSheetConfigContext()
  const [, toggleDrawer] = useDrawerStateContext()

  const handleApproval = useCallback(() => {
    mutate(
      {
        sheets: [sheet]
      },
      {
        onSuccess() {
          toggleDrawer()
          navigate('../' + sheet.name)
        }
      }
    )
  }, [mutate, navigate, sheet, toggleDrawer])

  const { data } = useVerifyMutationContext()

  return (
    <>
      <ChangeList
        changes={data as ChangeRecordList | ChangeRecord}
        actionButton={
          <Button type='primary' loading={isLoading} onClick={handleApproval}>
            Approve
          </Button>
        }
      />
      <Button
        size='large'
        type='link'
        onClick={goPrev}
        className={sx.btn}
        icon={<LeftOutlined />}
      >
        To config edit
      </Button>
    </>
  )
}
