import { message } from 'antd'

import { makeMessageClosable } from '@/shared/lib/message/makeClosable'
import { FetcherError } from '@/shared/lib/fetcher'

export const showDefaultErrorMessage = (error: FetcherError) => {
  message.error({
    content: `Failed to ${error.reqDescription}, reason : ${error.reason}`,
    ...makeMessageClosable()
  })
}
