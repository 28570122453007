import React, { useContext } from 'react'

import { useComplexState } from '@/shared/lib/hooks/useComplexState'
import { SheetConfig } from '@/entities/Sheet'

const useTypeUtil = () => useComplexState<SheetConfig>()
type SheetContextType = ReturnType<typeof useTypeUtil>
export const SheetConfigContext = React.createContext<SheetContextType>(
  [] as unknown as SheetContextType
)

export const useSheetConfigContext = () => useContext(SheetConfigContext)
