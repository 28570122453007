import { useMutation, useQueryClient } from 'react-query'

import { fetcher } from '@/shared/lib/fetcher/config'
import { sheetKeys } from '@/entities/Sheet/model'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { Sheet } from '@/entities/Sheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'
import { RowsDataSet } from '@/features/table/lib'

type DeleteRowParams = {
  rowsDataSet: RowsDataSet
  sheet: Sheet
}

export const useDeleteRowsMutation = () => {
  const qClient = useQueryClient()

  return useMutation<RowsDataSet, FetcherError, DeleteRowParams>(
    async ({ sheet, rowsDataSet }) => {
      const response = await fetcher<RowsDataSet, RowsDataSet>({
        description: `Delete row(s)`,
        method: 'delete',
        url: `/spreadsheets/${sheet.spreadsheet_id}/sheets/${sheet.id}/data`,
        data: rowsDataSet
      })

      return response.data
    },
    {
      onSuccess(data, { sheet }) {
        qClient.invalidateQueries(
          sheetKeys.dataOf(sheet.spreadsheet_id, sheet.id)
        )
      },
      onError: showDefaultErrorMessage
    }
  )
}
