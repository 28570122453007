import React, { FC } from 'react'
import { Button, List, Popover } from 'antd'
import { Link } from 'react-router-dom'
import { PlusOutlined } from '@ant-design/icons'

import {
  SpreadsheetMetadata,
  useGetSpreadsheetsQuery
} from '@/entities/Spreadsheet'
import { DeleteSpreadsheet } from '@/features/spreadsheet/delete'
import { CreateBlankSpreadsheetForm } from '@/features/spreadsheet/create/ui'

import sx from './styles.less'

const ListItem = (ss: SpreadsheetMetadata) => (
  <List.Item
    className={sx.row}
    actions={[<DeleteSpreadsheet spreadsheet={ss} />]}
  >
    <Link className={sx.link} to={ss.id + '/' + (ss.default_sheet_name || '')}>
      <div className={sx.rowData}>
        <h4>{ss.name}</h4>
        <span className={sx.rowAuthorData}>
          Owner: {ss.owner_first_name + ' ' + ss.owner_last_name}
        </span>
      </div>
    </Link>
  </List.Item>
)

export const SpreadsheetsListPage: FC = () => {
  const { data: spreadsheets, isLoading } = useGetSpreadsheetsQuery()

  return (
    <section className={sx.root}>
      <div className={sx.heading}>
        <h2 className={sx.title}>Spreadsheets</h2>
        <Popover
          placement='bottomRight'
          trigger='click'
          content={<CreateBlankSpreadsheetForm />}
        >
          <Button icon={<PlusOutlined />} />
        </Popover>
      </div>
      <List<SpreadsheetMetadata>
        className={sx.listBody}
        bordered
        rowKey='id'
        size='large'
        split
        loading={isLoading}
        dataSource={spreadsheets}
        renderItem={ListItem}
      />
    </section>
  )
}
