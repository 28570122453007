import React from 'react'
import { Skeleton } from 'antd'

import sx from './styles.less'

export const TablePageSkeleton = () => (
  <div>
    <div className={sx.header}>
      <Skeleton.Button className={sx.headerElement} size='default' active />
      <Skeleton.Button className={sx.headerElement} size='default' active />
    </div>
    <hr />
    {Array.from({ length: 20 }, () => (
      <Skeleton.Button key={Math.random()} className={sx.row} block active />
    ))}
  </div>
)
