enum NewEntryDefaultType {
  VALUE = 'VALUE',
  TODAY = 'TODAY',
  UUID = 'UUID'
  // SQL in the future?
}

interface NewEntryConfig {
  default_type?: NewEntryDefaultType
  default_val?: string
}

export enum ValidationType {
  PYTHON = 'PYTHON',
  PYTHON_DROPDOWN = 'PYTHON_DROPDOWN'
}

export interface ValidationConfig {
  validation_type?: ValidationType | null
  python_code?: string
}

export enum ColumnValueType {
  Text = 'Text',
  Integer = 'Integer',
  Double = 'Double',
  Date = 'Date',
  DateTime = 'DateTime',
  //actually stored as 0 and 1
  Boolean = 'Boolean',

  // Magic Values
  AutoIncrement = 'AutoIncrement',
  UUID = 'UUID',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt'
}

export interface ColumnParams {
  name: string
  column_type: ColumnValueType
  length: number | null
  required: boolean
  is_primary: boolean

  new_entry_config?: NewEntryConfig
  validation_config: ValidationConfig
}

export interface Column extends ColumnParams {
  id?: string
  sheet_id?: string
  position?: number
}

export type ColumnTypes = [ColumnValueType, 'Required' | '']
