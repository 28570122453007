import React, { FC, useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

import { useGetSpreadsheetByIDQuery } from '@/entities/Spreadsheet/api'
import { useSpreadsheetParams } from '@/shared/lib/hooks'
import { InfoText } from '@/shared/ui/InfoText'
import { CreateFirstSheet } from '../ui/CreateFirstSheet'

export const RedirectToActiveSheet: FC = () => {
  const { ss_id } = useSpreadsheetParams()
  const navigate = useNavigate()

  const { data, isLoading, error } = useGetSpreadsheetByIDQuery(ss_id!)

  useEffect(() => {
    if (data?.sheets[0]) {
      navigate(`/spreadsheets/${ss_id}/` + data?.sheets[0].name, {
        replace: true
      })
    }
  }, [data, navigate, ss_id])

  if (isLoading) return <InfoText>Loading...</InfoText>

  if (!data)
    return (
      <pre>
        <InfoText>
          Failed to get the spreadsheet data, reason:{' '}
          {error?.reason || 'unknown'}.
        </InfoText>
        <InfoText>Try reloading a page</InfoText>
      </pre>
    )

  if (!data.sheets.length) return <CreateFirstSheet spreadsheet={data} />

  return <Navigate to={`${ss_id}/` + data.sheets[0].name} />
}
