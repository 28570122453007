import React, { FC, useCallback, useEffect } from 'react'

import { appConfig } from '@/shared/config/appConfig'
import { useGoogleLoginMutation } from '../api/useGoogleLoginMutation'

const buttonElementID = 'SignInViaGoogleBtn'

type Props = {
  toggleAuthPending: () => void
}

export const GoogleAuthButton: FC<Props> = ({ toggleAuthPending }) => {
  const { mutate } = useGoogleLoginMutation()

  const handleLogin = useCallback(
    (response: google.CredentialResponse) => {
      toggleAuthPending()
      mutate(response, {
        onSettled: toggleAuthPending
      })
    },
    [mutate, toggleAuthPending]
  )

  useEffect(() => {
    window.google.accounts.id.initialize({
      client_id: appConfig.googleAuthClientId,
      callback: handleLogin
    })
    window.google.accounts.id.renderButton(
      document.getElementById(buttonElementID)!,
      { theme: 'filled_blue', size: 'large' }
    )
  }, [handleLogin])

  return <div id={buttonElementID} />
}
