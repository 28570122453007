import { ExclamationCircleFilled, LockFilled } from '@ant-design/icons'
import { Tooltip } from 'antd'
import React, { ReactNode } from 'react'

import { CellMetadata } from '@/entities/Sheet'

import sx from './styles.less'

const colorMap = {
  required: 'rgb(250,162,20)',
  error: '#ff7474',
  disabled: 'rgb(114,114,114)'
}

export const getMetaIndicators = (metadata?: CellMetadata): ReactNode => {
  if (!metadata) return null

  const { disabled_msg, err_msg } = metadata
  const indicators: JSX.Element[] = []

  if (disabled_msg) {
    indicators.push(
      <Tooltip title={disabled_msg} color={colorMap.disabled} key='disabled'>
        <LockFilled style={{ color: colorMap.disabled }} />
      </Tooltip>
    )
  }

  if (err_msg) {
    indicators.push(
      <Tooltip title={err_msg} color={colorMap.error} key='error'>
        <ExclamationCircleFilled style={{ color: colorMap.error }} />
      </Tooltip>
    )
  }

  return indicators.length ? (
    <div className={sx.indicatorsContainer}>{indicators}</div>
  ) : null
}
