import { useMutation } from 'react-query'

import { FetcherError } from '@/shared/lib/fetcher/error'
import { fetcher } from '@/shared/lib/fetcher/config'
import { Sheet } from '@/entities/Sheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'
import { SheetsWrapped } from '@/entities/Sheet/api/mutations/lib'
import { ChangeRecordList } from '@/shared/lib/types'

type VerifyConfigParams = SheetsWrapped<Sheet>

export const useConfigVerifyMutation = () =>
  useMutation<ChangeRecordList, FetcherError, VerifyConfigParams>(
    async (payload) => {
      const { spreadsheet_id } = payload.sheets[0]

      const response = await fetcher<VerifyConfigParams, ChangeRecordList>({
        method: 'put',
        description: 'Update sheet',
        params: {
          on_validation_err: 'RAISE_ALL_ERRORS'
        },
        url: `/spreadsheets/${spreadsheet_id}/sheets_verify_config`,
        data: payload
      })

      return response.data
    },
    {
      onError: showDefaultErrorMessage
    }
  )
