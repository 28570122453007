import { fetcher } from '@/shared/lib/fetcher/config'
import { useMutation } from 'react-query'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { SheetsConfigsList } from '@/features/spreadsheet'

type FileUploadPayload = {
  db_id: string
  formData: FormData
}

export const useFileUploadMutation = () =>
  useMutation<SheetsConfigsList, FetcherError, FileUploadPayload>(
    async ({ db_id, formData }) => {
      const response = await fetcher<FormData, SheetsConfigsList>({
        method: 'post',
        description: `Upload File`,
        url: `/spreadsheets/${db_id}/sheets_upload_file`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })

      return response.data
    }
  )
