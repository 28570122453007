import React, { FC, useCallback, useMemo, useState } from 'react'
import { Button, Divider, Form, Select } from 'antd'
import { LeftOutlined } from '@ant-design/icons'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { FormProviderProps } from 'antd/es/form/context'
import set from 'lodash.set'

import { SheetsConfigsList } from '@/features/spreadsheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'

import { FormValues } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm'
import { SheetEditor } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm/ui/FormSteps/SpreadsheetEditor/SheetEditor'
import { useStepContext } from '@/features/sheet/create/createFromFile/ui/CreateFromFileForm/model/StepContext'
import { useConfigUploadMutation } from '@/features/sheet/create/createFromFile/api/useConfigUploadMutation'

import sx from './styles.less'

export const SpreadsheetEditor: FC = () => {
  const form = useFormInstance<FormValues>()
  const config: SheetsConfigsList = form.getFieldValue('config')

  const sheetsOptions = useMemo(
    () =>
      config.sheets.map((sheet) => ({
        label: sheet.name,
        value: sheet.name
      })),
    [config.sheets]
  )

  const [currentSheetName, setCurrentSheetName] = useState(
    sheetsOptions[0].value
  )
  const currentSheet = useMemo(
    () => config.sheets.find((sheet) => sheet.name === currentSheetName)!,
    [config.sheets, currentSheetName]
  )
  const onSheetChange = useCallback(
    (value: string) => setCurrentSheetName(value),
    []
  )

  // @ts-ignore to suppress undefined case complaints
  const onFormChange = useCallback<FormProviderProps['onFormChange']>(
    (formName, info) => {
      //this function fires on any value change in any underlying form and updates the main form's state with it
      const sheetIndex = config.sheets.indexOf(currentSheet)
      const colIndex = currentSheet.columns.findIndex(
        (col) => col.name === formName
      )
      const formState = form.getFieldsValue(true)

      for (const change of info.changedFields) {
        const path = [
          'config',
          'tables',
          sheetIndex,
          'columns',
          colIndex,
          ...(change.name as Array<string>)
        ]
        set(formState, path, change.value)
      }

      form.setFieldsValue(formState)
    },
    [config, currentSheet, form]
  )

  const { mutate, isLoading } = useConfigUploadMutation<'verify'>()
  const [, goPrev, goNext] = useStepContext()

  const verifyConfig = useCallback(() => {
    const { db_id, file, config } = form.getFieldsValue(true)
    const formData = new FormData()
    formData.append('file', file.file.originFileObj)

    const json = JSON.stringify(config)
    const blob = new Blob([json], {
      type: 'application/json'
    })
    formData.append('json_data', blob)

    mutate(
      { db_id, formData, action: 'verify' },
      {
        onSuccess(value) {
          form.setFields([{ name: 'schemaDescription', value }])
          goNext()
        },
        onError: showDefaultErrorMessage
      }
    )
  }, [form, goNext, mutate])

  return (
    <Form.Provider onFormChange={onFormChange}>
      <div className={sx.root}>
        <div className={sx.heading}>
          <div>
            <label className={sx.selectLabel}>Sheet to configure:&nbsp;</label>
            <Select
              size='large'
              onChange={onSheetChange}
              defaultValue={currentSheetName}
              options={sheetsOptions}
            />
          </div>
          <Button
            size='large'
            onClick={verifyConfig}
            loading={isLoading}
            type='primary'
          >
            Verify configuration
          </Button>
        </div>

        <Divider dashed>Columns</Divider>

        <SheetEditor sheet={currentSheet} />

        <Button
          size='large'
          type='link'
          onClick={goPrev}
          className={sx.btn}
          icon={<LeftOutlined />}
        >
          To file upload
        </Button>
      </div>
    </Form.Provider>
  )
}
