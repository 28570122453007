import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import 'ag-grid-enterprise'
import { ColDef, GetContextMenuItemsParams } from 'ag-grid-enterprise'
import { AgGridReact } from 'ag-grid-react'
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'

import { ColumnTypes } from '@/entities/Column'
import { Sheet } from '@/entities/Sheet'
import { useGetSheetDataQuery } from '@/entities/Sheet/api'
import { Spreadsheet } from '@/entities/Spreadsheet'
import { useDeleteRowsMutation } from '@/features/table/deleteRow'
import { useEditCellHandler } from '@/features/table/setCellValue'

import { deleteRowHandler } from './model'
import {
  columnTypes,
  defaultColDef,
  footerWidgets,
  composeCellClassnames
} from './lib'

import 'ag-grid-community/dist/styles/ag-grid.css'
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'
import sx from './styles.less'

type Props = {
  spreadsheet: Spreadsheet
  sheet: Sheet
}

export const SheetTable = ({ spreadsheet, sheet }: Props) => {
  const gridRef = useRef<AgGridReact>(null)
  const { data: sheetRows, isLoading } = useGetSheetDataQuery(
    spreadsheet.id,
    sheet.id
  )

  useEffect(() => {
    const api = gridRef.current?.api
    if (api) {
      if (isLoading) {
        api.showLoadingOverlay()
      } else if (!sheetRows?.length) {
        api.showNoRowsOverlay()
      } else {
        api.hideOverlay()
      }
    }
  }, [isLoading, sheetRows?.length])

  const editCellHandler = useEditCellHandler(sheet)
  const { mutate: deleteMutation } = useDeleteRowsMutation()

  const getContextMenuItems = useCallback(
    (params: GetContextMenuItemsParams) => {
      if (!params.node || !sheetRows?.length) return []

      return [
        {
          name: 'Delete row',
          action: () =>
            deleteRowHandler({
              rowData: params.node!.data,
              mutate: deleteMutation,
              sheet
            })
        },
        'copy'
      ]
    },
    [deleteMutation, sheet, sheetRows?.length]
  )

  const colDefs = useMemo(
    () =>
      sheet.columns.map<ColDef>((colConfig) => {
        const columnTypes: ColumnTypes = [
          colConfig.column_type,
          colConfig.required ? 'Required' : '' // adding '' for consistent array length
        ]
        return {
          type: columnTypes,
          colId: colConfig.id,
          field: colConfig.name,
          headerName: colConfig.name,
          cellClass: composeCellClassnames(columnTypes)
        }
      }),
    [sheet]
  )

  // const getRowId = useCallback((params) => {
  //   const primaryColNames = sheet.columns.filter(col => col.is_primary).map(col => col.name)
  //
  //   return primaryColNames.reduce(
  //     (acc, val) => acc + '/' + params.data[val],
  //     sheet.name
  //   )
  //
  //
  // }, [sheet.name, sheet.columns])

  return (
    <ErrorBoundary>
      <div className='ag-theme-alpine'>
        <AgGridReact
          ref={gridRef}
          className={sx.table}
          singleClickEdit
          readOnlyEdit
          onCellEditRequest={editCellHandler}
          stopEditingWhenCellsLoseFocus
          // getRowId={getRowId}
          enableRangeSelection
          getContextMenuItems={getContextMenuItems}
          columnTypes={columnTypes}
          defaultColDef={defaultColDef}
          columnDefs={colDefs}
          statusBar={footerWidgets}
          rowData={sheetRows}
        />
      </div>
    </ErrorBoundary>
  )
}
