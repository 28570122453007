import { ColumnTypes, ColumnValueType } from '@/entities/Column'
import { cx } from '@/shared/lib/classnames'

import sx from '../styles.less'

export const composeCellClassnames = (colTypes: ColumnTypes) => {
  const [baseType] = colTypes

  switch (baseType) {
    case ColumnValueType.Boolean:
      return cx(sx.centerValue, sx.boolMark)

    default:
      return undefined
  }
}
