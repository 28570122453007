import { useSheetConfigContext } from '../../../../model'

const useTypeUtil = () => useSheetConfigContext()[0]

type ValidationResult = {
  isValid: boolean
  error: string
}

const resultIfValid = {
  isValid: true,
  error: ''
}

export const validateConfig = (
  state: ReturnType<typeof useTypeUtil>
): ValidationResult => {
  const rules: ValidationResult[] = [
    {
      isValid: !!state.name,
      error: `Sheet's name is not specified`
    },
    {
      isValid: state.columns.some((column) => column.is_primary),
      error: `No primary column selected`
    }
  ]

  return rules.find((rule) => !rule.isValid) || resultIfValid
}
