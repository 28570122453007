import { Column, ColumnValueType } from '@/entities/Column'

const { Integer, Double, Date } = ColumnValueType

type NewRowValues = Record<Column['name'], any>

export const normalizeRowValues = (
  columns: Column[],
  row_data: NewRowValues,
  rowFieldsTouchState?: NewRowValues
): NewRowValues => {
  const normalizedRowData = {} as NewRowValues

  Object.entries(row_data).forEach(([columnName, value]) => {
    const columnType = columns.find(
      (col) => col.name === columnName
    )?.column_type
    if (!columnType) return

    let normalizedValue: any = value
    switch (columnType) {
      case Integer:
      case Double: {
        const parsedValue = parseFloat(value)
        normalizedValue = isNaN(parsedValue) ? undefined : parsedValue
        break
      }

      case Date: {
        if (typeof value === 'string') {
          normalizedValue = value

          if (!value.length) normalizedValue = null
        }
        if (typeof value === 'object')
          normalizedValue = value?.format?.('YYYY-MM-DD') || null
        break
      }

      default:
        if (typeof value === 'string' && !value.length) return
    }

    if (value === undefined) {
      if (rowFieldsTouchState && columnName in rowFieldsTouchState)
        normalizedValue = null
      else normalizedValue = value
    }

    if (value === null) normalizedValue = value

    normalizedRowData[columnName] = normalizedValue
  })

  return normalizedRowData
}
