import { fetcher } from '@/shared/lib/fetcher/config'
import { useQuery } from 'react-query'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { Spreadsheet } from '../../model/types'
import { FetcherError } from '@/shared/lib/fetcher/error'

export const useGetSpreadsheetByIDQuery = (id: string) =>
  useQuery<
    Spreadsheet,
    FetcherError,
    Spreadsheet,
    ReturnType<typeof spreadsheetKeys.withId>
  >(spreadsheetKeys.withId(id), async ({ queryKey }) => {
    const id = queryKey[1]
    const response = await fetcher<Spreadsheet>({
      description: 'Get spreadsheet by id',
      url: `/spreadsheets/${id}`
    })
    return response.data
  })
