import { useMutation } from 'react-query'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { fetcher } from '@/shared/lib/fetcher/config'
import { SheetConfig } from '@/entities/Sheet'
import { showDefaultErrorMessage } from '@/shared/lib/message'
import { SheetsWrapped } from '@/entities/Sheet/api/mutations/lib'
import { ChangeRecordList } from '@/shared/lib/types'

export type ConfigUploadPayload = SheetsWrapped<SheetConfig>

export const useConfigVerifyMutation = () =>
  useMutation<ChangeRecordList, FetcherError, ConfigUploadPayload>(
    async (payload) => {
      const { spreadsheet_id } = payload.sheets[0]

      const formData = new FormData()
      const json = JSON.stringify(payload)
      const blob = new Blob([json], {
        type: 'application/json'
      })
      formData.append('json_data', blob)

      const response = await fetcher<FormData, ChangeRecordList>({
        method: 'post',
        description: `Verify new sheet config`,
        params: {
          create_type: 'JSON_CONFIG'
        },
        url: `/spreadsheets/${spreadsheet_id}/sheets_verify_config`,
        data: formData
      })

      return response.data
    },
    {
      onError: showDefaultErrorMessage
    }
  )
