import { useMutation } from 'react-query'
import { fetcher } from '@/shared/lib/fetcher/config'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { Sheet } from '@/entities/Sheet'
import { RowsDataSet } from '@/features/table/lib'

type UpdateParams = {
  rows: Array<{
    column_names: string[]
    curr_row_data: object
    row_data: object
  }>
}

type UpdateRequestPayload = UpdateParams & {
  update_type: 'UPDATE_SUBMIT'
}

type MutationOptions = {
  params: UpdateParams
  sheet: Sheet
}

export const useCellMutation = () =>
  useMutation<RowsDataSet, FetcherError, MutationOptions>(
    async ({ params, sheet }) => {
      const response = await fetcher<UpdateRequestPayload, RowsDataSet>({
        method: 'put',
        description: 'Update values',
        params: {
          on_validation_err: 'RAISE_ON_FIRST_ERR'
        },
        url: `/spreadsheets/${sheet.spreadsheet_id}/sheets/${sheet.id}/data`,
        data: {
          rows: params.rows,
          update_type: 'UPDATE_SUBMIT'
        }
      })

      return response.data
    }
  )
