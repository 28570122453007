import React, { FC } from 'react'
import { Drawer } from 'antd'

import { CreateSheetsFromFileForm } from '@/features/sheet/create/createFromFile'
import { useBooleanState } from '@/shared/lib/hooks'

export const CreateSheetsFromFileDrawer: FC = ({ children }) => {
  const [isDrawerOpen, toggleDrawer] = useBooleanState()

  return (
    <>
      <div onClick={toggleDrawer}>{children}</div>
      <Drawer
        destroyOnClose
        title='Create from file'
        visible={isDrawerOpen}
        onClose={toggleDrawer}
        width='clamp(50vw, 1000px, 100vw)'
      >
        <CreateSheetsFromFileForm />
      </Drawer>
    </>
  )
}
