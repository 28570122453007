import React from 'react'

import { useGetSpreadsheetByIDQuery } from '@/entities/Spreadsheet'
import { useSpreadsheetParams } from '@/shared/lib/hooks'
import { InfoText } from '@/shared/ui/InfoText'

import { RedirectToActiveSheet } from './RedirectToActiveSheet'
import { SheetTable, TablePageSkeleton, SheetTableHeader } from './ui'

import sx from './styles.less'

export const SpreadsheetPage = () => {
  const { ss_id, sheet_name } = useSpreadsheetParams()
  const { data: spreadsheet, isLoading } = useGetSpreadsheetByIDQuery(ss_id)

  if (isLoading) return <TablePageSkeleton />

  if (!spreadsheet) return <InfoText>This spreadsheet does not exist</InfoText>

  const activeSheet = spreadsheet.sheets.find(
    (sheet) => sheet.name === sheet_name
  )

  if (!activeSheet) {
    return <RedirectToActiveSheet />
  }

  return (
    <section className={sx.main}>
      <SheetTableHeader
        spreadsheetName={spreadsheet.name}
        sheet={activeSheet}
      />
      <SheetTable spreadsheet={spreadsheet} sheet={activeSheet} />
    </section>
  )
}
