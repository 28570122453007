import React, { FC, useEffect } from 'react'
import { message, Typography } from 'antd'
import { Navigate } from 'react-router-dom'
import { useQuery } from 'react-query'

import { AuthWithGoogle } from '@/features/auth'
import { viewerKeys } from '@/entities/Viewer/model'
import { CREDS_FLAG_KEY_NAME } from '@/features/auth/lib'
import { makeMessageClosable } from '@/shared/lib/message'
import { fetchOnDemand } from '@/shared/lib/query'

import sx from './styles.less'

const { Title, Paragraph } = Typography

export const SignInPage: FC = () => {
  const { data } = useQuery(viewerKeys.account, fetchOnDemand)

  useEffect(() => {
    if (localStorage.getItem(CREDS_FLAG_KEY_NAME)) {
      localStorage.removeItem(CREDS_FLAG_KEY_NAME)
      message.info({
        content: 'Your credentials have expired, please sign in',
        duration: 10,
        ...makeMessageClosable()
      })
    }
  }, [])

  if (data) return <Navigate to='/' />

  return (
    <main className={sx.root}>
      <div className={sx.textWrapper}>
        <Title level={2} className={sx.title}>
          Welcome to BluGrid
        </Title>
        <Paragraph className={sx.text}>Please, sign in to continue</Paragraph>
      </div>
      <div className={sx.widgetsWrapper}>
        <AuthWithGoogle />
      </div>
    </main>
  )
}
