import { ValidationType, ColumnValueType } from '@/entities/Column/model'

export const columnTypeOptions = Object.values(ColumnValueType).map(
  (value) => ({ label: value, value })
)
export const validationTypeOptions = [
  { label: '-', value: null },
  ...Object.values(ValidationType).map<{ label: string; value: any }>(
    (value) => ({ label: value, value })
  )
]

export const nameRules = [
  { required: true, message: 'Enter column name, please' }
]
