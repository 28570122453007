import { Sheet } from '@/entities/Sheet'
import { useMemo } from 'react'

export const useCreateEmptyRowState = (sheet: Sheet) =>
  useMemo(
    () =>
      sheet.columns.reduce((acc, val) => {
        acc[val.name] = undefined
        return acc
      }, {} as any),
    [sheet]
  )
