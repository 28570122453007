import axios, { AxiosPromise, AxiosRequestConfig } from 'axios'
import { FetcherError } from './error'
import { appConfig } from '@/shared/config/appConfig'

export const axiosInstance = axios.create({
  baseURL: appConfig.backendUrl,
  timeout: 50000
})

axiosInstance.interceptors.response.use((response) => {
  if ('success' in response.data && !response.data.success) {
    // eslint-disable-next-line no-throw-literal
    throw { request: response.request }
  }
  return response
})

interface FetchConfig<Data> extends AxiosRequestConfig<Data> {
  description?: string
}

export const fetcher = <Payload = unknown, Response = Payload>({
  description,
  ...config
}: FetchConfig<Payload>) =>
  axiosInstance(config).catch((error) => {
    if (axios.isCancel(error)) {
      !appConfig.is_prod() && console.log(`Request ${config.url} was cancelled`)
    } else if ('request' in error) {
      throw new FetcherError({ request: error.request, description })
    } else throw error
  }) as AxiosPromise<Response>
