import React, { FC } from 'react'
import { Layout } from 'antd'
import { AppHeader } from './AppHeader'
import { useBooleanState } from '@/shared/lib/hooks/useBooleanState'
import { AppSider } from './AppSider'
import { Outlet } from 'react-router-dom'

import sx from './styles.less'

const { Content } = Layout

export const AppLayout: FC = () => {
  const siderState = useBooleanState()
  const [isOpen] = siderState

  return (
    <Layout className={sx.appLayout}>
      <AppHeader siderState={siderState} />
      <Layout>
        <AppSider isOpen={isOpen} />
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}
