import React, { FC } from 'react'
import { Tabs } from 'antd'

import { SheetConfig } from '@/entities/Sheet'
import { ColumnEditor } from '@/entities/Column'

import sx from './styles.less'

type Props = {
  sheet: SheetConfig
}

export const SheetEditor: FC<Props> = ({ sheet }) => (
  <Tabs
    size='large'
    type='card'
    defaultActiveKey={sheet.columns[0].name}
    className={sx.tabs}
  >
    {sheet.columns.map((column) => (
      <Tabs.TabPane tab={column.name} key={column.name}>
        <ColumnEditor nameChangeDisabled column={column} />
      </Tabs.TabPane>
    ))}
  </Tabs>
)
