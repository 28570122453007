import React, { FC, useEffect } from 'react'
import { Menu, Popover } from 'antd'
import { FileExcelOutlined, FileOutlined } from '@ant-design/icons'

import { Spreadsheet } from '@/entities/Spreadsheet'
import { CreateBlankSheetDrawer } from '@/features/sheet/create/createBlank'
import { useBooleanState } from '@/shared/lib/hooks'

import { CreateSheetsFromFileDrawer } from './CreateSheetsFromFileDrawer'

import sx from './styles.less'

type PropsOfPopover = {
  closePopover: () => void
  spreadsheet: Spreadsheet
}

const CreateOptions: FC<PropsOfPopover> = ({ closePopover, spreadsheet }) => (
  <Menu onClick={closePopover} className={sx.popover}>
    <Menu.Item icon={<FileOutlined />} key='blank'>
      <CreateBlankSheetDrawer spreadsheet={spreadsheet}>
        Create blank
      </CreateBlankSheetDrawer>
    </Menu.Item>
    <Menu.Item icon={<FileExcelOutlined />} key='file'>
      <CreateSheetsFromFileDrawer>Create from file</CreateSheetsFromFileDrawer>
    </Menu.Item>
  </Menu>
)

type Props = {
  spreadsheet: Spreadsheet
}

export const CreateSheetPopover: FC<Props> = ({ children, spreadsheet }) => {
  const [isOpen, , open, close] = useBooleanState()

  useEffect(() => {
    if (isOpen) {
      let closerFn: EventListener
      closerFn = (event) => {
        //Because popover is rendered in a portal,
        //we can check if the click target is anywhere but inside the popover
        if ((event.target as HTMLElement).closest('#root')) close()
      }
      //using timeout to avoid setting listener before current event bubbles up to window
      setTimeout(() => window.addEventListener('click', closerFn))

      return () => window.removeEventListener('click', closerFn)
    }
  }, [close, isOpen])

  return (
    <Popover
      placement='bottomLeft'
      visible={isOpen}
      content={<CreateOptions spreadsheet={spreadsheet} closePopover={close} />}
    >
      <div onClick={open}>{children}</div>
    </Popover>
  )
}
