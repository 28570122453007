import { FC, ReactElement } from 'react'

export const getIndicatorRightOffset = (idx: number): number => 5 + 17 * idx

const CELL_PADDING = 12
export const containerStyle = {
  transform: `translateX(-${CELL_PADDING}px)`,
  width: `calc(100% + ${CELL_PADDING * 2}px)`,
  padding: `0 ${CELL_PADDING}px`
}

export const colorMap = {
  required: 'rgb(250,162,20)',
  error: '#ff7474',
  disabled: 'rgb(134,134,134)'
}

export const RenderChildren: FC = ({ children }) => children as ReactElement
