import { Column, ColumnValueType } from '@/entities/Column'

const { Boolean, Integer, Double, Date, DateTime } = ColumnValueType

export const defineTrigger = (
  column: Column,
  isDropdown: boolean | undefined,
  isLastFieldInForm: boolean
): 'onChange' | 'onBlur' => {
  if (isLastFieldInForm || isDropdown) {
    console.log(column.name, "is 'onChange'")
    return 'onChange'
  }

  switch (column.column_type) {
    case Boolean:
    case Date:
    case DateTime:
      return 'onChange'

    case Integer:
    case Double:
      return 'onBlur'

    default:
      return 'onBlur'
  }
}
