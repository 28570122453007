import { useQueryClient } from 'react-query'
import { viewerKeys } from '@/entities/Viewer'
import { useCallback } from 'react'
import { eraseAppJWT } from './lib'

export const useLogout = () => {
  const qClient = useQueryClient()

  return useCallback(() => {
    qClient.setQueryData(viewerKeys.account, null)
    eraseAppJWT()
  }, [qClient])
}
