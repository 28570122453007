import React, { FC, memo, PropsWithChildren } from 'react'
import { Tooltip } from 'antd'

import { RowMetadataObject } from '@/entities/Sheet'
import { cx } from '@/shared/lib/classnames'

import { colorMap, RenderChildren } from './lib'

import sx from './styles.less'

type Props = {
  colName?: string
  meta?: RowMetadataObject
}

export const MetaIndicators: FC<Props> = memo(({ children, meta, colName }) => {
  if (!meta || !colName)
    return <div className={sx.metaContainer}>{children}</div>

  const columnMetadata = meta.columns[colName]
  if (!columnMetadata) return <div className={sx.metaContainer}>{children}</div>

  const { disabled_msg, err_msg } = columnMetadata

  let DisabledIndicator = RenderChildren
  if (disabled_msg)
    DisabledIndicator = ({ children }: PropsWithChildren<any>) => (
      <Tooltip title={disabled_msg} color={colorMap.disabled}>
        {children}
      </Tooltip>
    )

  let errorIndicator = null
  if (err_msg)
    errorIndicator = (
      <Tooltip placement='rightTop' title={err_msg} color={colorMap.error}>
        <div
          className={cx(sx.errorIndicator)}
          style={{
            right: 0,
            color: colorMap.error
          }}
        />
      </Tooltip>
    )

  return (
    <DisabledIndicator>
      <div
        className={cx(sx.metaContainer, disabled_msg && sx.disabledContainer)}
      >
        {errorIndicator}
        {children}
      </div>
    </DisabledIndicator>
  )
})
