import { ColumnTypes, ColumnValueType } from '@/entities/Column'
import { ColDef } from 'ag-grid-enterprise'

const baseColTypes = Object.values(ColumnValueType)

//serves as a type guard
export const hasColumnTypes = (
  colTypes: ColDef['type']
): colTypes is ColumnTypes => {
  if (!colTypes || typeof colTypes === 'string') return false

  return (
    baseColTypes.includes(colTypes[0] as ColumnValueType) &&
    (colTypes[1] === 'Required' || colTypes[1] === '')
  )
}
