import React, { FC } from 'react'

import { useStepContext } from '../../model'
import { FileUpload } from './FileUpload'
import { SpreadsheetEditor } from './SpreadsheetEditor'
import { DiffComparison } from './DiffComparison'
import sx from './styles.less'
import { CheckOutlined, EditOutlined, UploadOutlined } from '@ant-design/icons'
import { Steps } from 'antd'

const { Step } = Steps

const StepContent: FC = () => {
  const [currentStep] = useStepContext()

  switch (currentStep) {
    case 0: {
      return <FileUpload />
    }
    case 1: {
      return <SpreadsheetEditor />
    }
    case 2: {
      return <DiffComparison />
    }
    default:
      return <div />
  }
}

export const FormSteps: FC = () => {
  const [currentStep] = useStepContext()

  return (
    <div>
      <Steps current={currentStep} className={sx.steps}>
        <Step icon={<UploadOutlined />} title='Upload file' />
        <Step icon={<EditOutlined />} title='Edit configuration' />
        <Step icon={<CheckOutlined />} title='Confirm' />
      </Steps>
      <div>
        <StepContent />
      </div>
    </div>
  )
}
