import { ColDef, StatusPanelDef } from 'ag-grid-enterprise'
import { message } from 'antd'

import { getCellMetadata } from '@/entities/Sheet/lib'
import { makeMessageClosable } from '@/shared/lib/message'
import { ColumnValueType } from '@/entities/Column/model'
import { CellEditor } from '@/features/table/setCellValue'
import { CellRenderer } from '@/features/table/viewCell'

import { SheetsPanel } from '../SheetsPanel'

/** Is used to remove console warnings about non-standard column types */
export const columnTypes = Object.keys(ColumnValueType)
  .concat(['Required', ''])
  .reduce((acc: any, key) => {
    acc[key] = {}
    return acc
  }, {})

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  cellRenderer: CellRenderer,
  cellEditor: CellEditor,
  editable: ({ data, colDef }) => {
    const metadata = getCellMetadata({ data, colDef })

    return !metadata?.disabled_msg
  },
  suppressKeyboardEvent: ({ data, colDef, editing, event }) => {
    const isDeleteAttempt =
      !editing && (event.key === 'Delete' || event.key === 'Backspace')

    /** Preventing removal of required fields */
    const metadata = getCellMetadata({ data, colDef })
    if (metadata?.required_msg && isDeleteAttempt) {
      message.info({
        content: `Can't delete as the column is marked "required"`,
        ...makeMessageClosable()
      })
      event.preventDefault()
      return true
    }

    /** On text/number fields, pressing Delete opens the editor with the last character of the value removed,
     which is weird, so we disable this behaviour*/
    if (isDeleteAttempt) event.preventDefault()

    /** Disables default `submit-on-Enter` behaviour and allows for precise control over 'Enter' clicks from within a cell editor*/
    if (editing && event.key === 'Enter') return true

    return false
  }
}

export const footerWidgets: { statusPanels: StatusPanelDef[] } = {
  statusPanels: [
    {
      align: 'left',
      statusPanel: SheetsPanel
    },
    {
      align: 'right',
      statusPanel: 'agAggregationComponent'
    }
  ]
}
