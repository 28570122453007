import React, { FC } from 'react'
import { Button } from 'antd'
import { useLogout } from '../../model'

export const LogoutButton: FC = () => {
  const logout = useLogout()

  return (
    <Button type='link' danger onClick={logout}>
      Log out
    </Button>
  )
}
