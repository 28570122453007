import { useCallback, useState } from 'react'

export const useBooleanState = (
  defState = false
): [
  isTrue: boolean,
  toggle: () => void,
  setTrue: () => void,
  setFalse: () => void
] => {
  const [isTrue, setState] = useState<boolean>(defState)

  const toggle = useCallback(() => setState((prev) => !prev), [])
  const setTrue = useCallback(() => setState(true), [])
  const setFalse = useCallback(() => setState(false), [])

  return [isTrue, toggle, setTrue, setFalse]
}
