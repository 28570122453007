import { useMutation, useQueryClient } from 'react-query'
import { message } from 'antd'
import { fetcher } from '@/shared/lib/fetcher/config'
import { spreadsheetKeys } from '@/entities/Spreadsheet'
import { SpreadsheetIdentifiers } from '../../model/types'
import { FetcherError } from '@/shared/lib/fetcher/error'
import { makeMessageClosable } from '@/shared/lib/message'

export const useDeleteSpreadsheetMutation = () => {
  const qClient = useQueryClient()

  return useMutation<SpreadsheetIdentifiers, FetcherError, string>(
    async (spreadsheetId) => {
      const response = await fetcher<SpreadsheetIdentifiers>({
        method: 'delete',
        description: 'Delete spreadsheet',
        url: `/spreadsheets/${spreadsheetId}`
      })

      return response.data
    },
    {
      onSuccess: (deletedSS) => {
        message.success({
          content: `Spreadsheet "${deletedSS.name}" was successfully deleted`,
          duration: 3,
          ...makeMessageClosable()
        })

        qClient.setQueryData<SpreadsheetIdentifiers[]>(
          spreadsheetKeys.list,
          (oldList) =>
            oldList?.length
              ? oldList.filter((ss) => ss.id !== deletedSS.id)
              : []
        )
      },
      onError: (error) => {
        message.error('Spreadsheet was not deleted, reason : ' + error.reason)
      }
    }
  )
}
