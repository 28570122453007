import { axiosInstance } from '@/shared/lib/fetcher/config'
import { APP_JWT_KEY_NAME, CREDS_FLAG_KEY_NAME } from '@/features/auth/lib'
import { eraseAppJWT } from '@/features/auth/logout/lib'

//Here we add credentials injection logic as well as logout on JWT expiration
export const initAuthInterceptors = () => {
  axiosInstance.interceptors.request.use((config) => {
    const appJWT = localStorage.getItem(APP_JWT_KEY_NAME)
    config.headers = {
      'BluGrid-App-JWT': appJWT || '',
      ...config.headers
    }

    return config
  })

  axiosInstance.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.status === 440) {
        eraseAppJWT()
        localStorage.setItem(CREDS_FLAG_KEY_NAME, 'true')
        window.location.reload()
      }
      throw err
    }
  )
}
