import React, { FC, useCallback, useState } from 'react'
import { Tabs, TabsProps } from 'antd'

import { ColumnEditor } from '@/entities/Column'
import { useSheetConfigContext } from '@/entities/Sheet/ui/ConfigSheetDrawer/ConfigSheetSteps/model'

import sx from './styles.less'
import { Column, ColumnValueType } from '@/entities/Column/model'

export const ColumnEditorsMenu: FC = () => {
  const [sheetConfig, setPart] = useSheetConfigContext()

  const [activeTabKey, setActiveTabKey] = useState(sheetConfig.columns[0].id)

  //@ts-ignore the undefined case of callback
  const editHandler = useCallback<TabsProps['onEdit']>(
    (removedColId, action) => {
      const columns = sheetConfig.columns
      switch (action) {
        case 'add': {
          const newColIndex = columns.length

          let newColName = ''
          let colCounter = newColIndex + 1
          do {
            const possibleName = 'new_column_' + colCounter++

            if (columns.every((col) => col.name !== possibleName))
              newColName = possibleName
          } while (!newColName)

          const newCol: Column = {
            name: newColName,
            column_type: ColumnValueType.Text,
            id: newColName,
            length: 128,
            is_primary: false,
            required: false,
            sheet_id: columns.find((col) => col.sheet_id)?.sheet_id,
            validation_config: {
              validation_type: null
            }
          }
          setActiveTabKey(newColName)
          setPart(['columns', newColIndex], newCol)
          break
        }
        case 'remove': {
          if (columns.length === 1) return

          const removedColIndex = columns.findIndex(
            (col) => col.id === removedColId
          )
          const colToRight = columns[removedColIndex + 1]
          const colToLeft = columns[removedColIndex - 1]
          setActiveTabKey((colToRight ?? colToLeft).id)

          const newColArray = columns.filter((col) => col.id !== removedColId)
          setPart(['columns'], newColArray)
          break
        }
      }
    },
    [setPart, sheetConfig.columns]
  )

  const onTabChange = useCallback((key: string) => {
    setActiveTabKey(key)
  }, [])
  return (
    <Tabs
      size='large'
      type='editable-card'
      className={sx.tabs}
      activeKey={activeTabKey}
      onEdit={editHandler}
      onTabClick={onTabChange}
    >
      {sheetConfig.columns.map((column) => (
        <Tabs.TabPane destroyInactiveTabPane tab={column.name} key={column.id}>
          <ColumnEditor column={column} />
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
}
