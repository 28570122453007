import React, { FC } from 'react'
import { Divider } from 'antd'
import { SheetEditor, SheetNameInput, VerifyButton } from './ui'

import sx from './styles.less'

export const SheetConfigMenu: FC = () => (
  <div className={sx.root}>
    <div className={sx.heading}>
      <SheetNameInput />
      <VerifyButton>Verify</VerifyButton>
    </div>
    <Divider dashed>Columns</Divider>
    <SheetEditor />
  </div>
)
