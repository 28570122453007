import { FC, StrictMode } from 'react'
import { AppRoutes } from './routes'

import 'antd/dist/antd.css'
import './styles.less'

import { ReactQueryDevtools } from 'react-query/devtools'
import { appConfig } from '@/shared/config/appConfig'
import { compose } from '@/shared/lib/compose'
import {
  withAntd,
  withAuth,
  withQueryClient,
  withRouter
} from '@/app/providers'

const AppContents: FC = () => (
  <>
    <AppRoutes />
    {appConfig.is_dev() && <ReactQueryDevtools position='top-right' />}
  </>
)

const ComposedApp: FC = compose(
  withRouter,
  withQueryClient,
  withAntd,
  withAuth
)(AppContents)

export const App: FC = () => (
  <StrictMode>
    <ComposedApp />
  </StrictMode>
)
