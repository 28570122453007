import {
  FileExcelOutlined,
  LeftOutlined,
  PlusOutlined
} from '@ant-design/icons'
import { Tooltip, Typography } from 'antd'
import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import { Spreadsheet } from '@/entities/Spreadsheet'
import { CreateBlankSheetDrawer } from '@/features/sheet/create/createBlank'
import { CreateSheetsFromFileDrawer } from '@/widgets/sheet/CreateSheetPopover/CreateSheetsFromFileDrawer'

import sx from './styles.less'

type Props = {
  spreadsheet: Spreadsheet
}

export const CreateFirstSheet: FC<Props> = ({ spreadsheet }) => {
  const navigate = useNavigate()
  const goToHomePage = () => navigate('/')

  return (
    <div className={sx.root}>
      <div className={sx.header}>
        <Tooltip title='Spreadsheets list' placement='bottomRight'>
          <LeftOutlined className={sx.arrow} onClick={goToHomePage} />
        </Tooltip>

        <Typography.Title className={sx.title} level={2}>
          {spreadsheet.name}
        </Typography.Title>
      </div>
      <div className={sx.buttonsContainer}>
        <CreateBlankSheetDrawer spreadsheet={spreadsheet}>
          <div className={sx.btn}>
            <PlusOutlined /> Create sheet manually
          </div>
        </CreateBlankSheetDrawer>
        <CreateSheetsFromFileDrawer>
          <div className={sx.btn}>
            <FileExcelOutlined /> Create sheet(s) from file
          </div>
        </CreateSheetsFromFileDrawer>
      </div>
    </div>
  )
}
